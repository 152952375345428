import SkeletonRow from '@/components/UI/molecules/tables/SkeletonRow'; // Asegúrate de proporcionar la ruta correcta
import {
    TableCellDetail,
    TableCellHeadDetail,
    TableContainerDetailOrder,
    TableHeadRow,
    TableRow,
} from '@/components/UI/molecules/tables/TablePrincipal';
import {ACLObj} from '@/components/auth/guard/AclGuard';
import {checkProcessedStatus} from '@/components/orderSimply/utils/orderConfirmHelpers';
import {assignBackgroundColor} from '@/components/orderSimply/utils/orderConfirmSimplyHelpers';
import {Can} from '@/context/AbilityContext';
import {ConfirmDeliveryScheduleInvoiceEnum, FeatureCodeEnum} from '@/enums/permissions.enum';
import useCheckAbilities from '@/hooks/useCheckAbilities';
import StatusText from '@components/UI/atoms/buttons/StatusText';
import {statusTextValues} from '@components/UI/atoms/buttons/constant/status-text';
import CustomPagination from '@components/UI/organisms/pagination/Pagination';
import {IFormDate} from '@components/orderSimply/interface';
import {fetchGetDownloadOrdersDetail} from '@components/orderSimply/redux/actions/OrderSimplyDownloadActions';
import {
    fetchGetOrdersConfirm,
    postOrderFilters,
} from '@components/orderSimply/redux/actions/OrdersSimplyActions';
import {AppDispatch, RootState} from '@config/store';
import {yupResolver} from '@hookform/resolvers/yup';
import {
    DescriptionOutlined,
    InsertDriveFileOutlined,
    Menu as MenuIcon,
    Search,
    VisibilityOutlined,
} from '@mui/icons-material';
import {
    Badge,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    InputLabel,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    OutlinedInput,
    Select,
    styled,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {es, enUS} from 'date-fns/locale';
import {DateTime} from 'luxon';
import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';

const InputLabelRol = styled(InputLabel)({
    '&.Mui-focused': {
        color: '#011B34',
    },
    color: '#011B34',
    marginTop: '-5px',
});

export const StyledSelect = styled(Select)({
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#C5D1D8',
    },
    height: '40px',
    minWidth: '100px',
    width: 'auto',
    marginLeft: '10px',
});

export const processString = (inputString: string, t: any) => {
    const recordsKeys = inputString.split(',');
    const records = recordsKeys.map((key: string) => {
        return statusTextValues[key];
    });

    const firstRecord = records[0];
    const remainingRecordsCount = records.length - 1;
    let remainingRecordsMessage = '';
    if (remainingRecordsCount > 0) {
        remainingRecordsMessage = `(+${remainingRecordsCount})`;
    }
    return t(`modelorama.table.status.${firstRecord}`) + remainingRecordsMessage;
};

const OrderConfirm: React.FC & {acl?: ACLObj} = () => {
    const userLang = localStorage.getItem('user-lang') || 'es';
    const lang = userLang === 'es' ? es : enUS;
    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const websiteType = useSelector((state: RootState) => state.logins.user.company.websiteType);
    const userType = useSelector((state: RootState) => state.logins.user.type);
    const binnacles = useSelector((state: RootState) => state.orderssimply.orderssimply);
    const currentPage = useSelector((state: RootState) => state.orderssimply.page);
    const limit = useSelector((state: RootState) => state.orderssimply.limit);
    const totalPages = useSelector((state: RootState) => state.orderssimply.totalPages);
    const loading = useSelector((state: RootState) => state.orderssimply.loading);
    const filters = useSelector((state: RootState) => state.orderssimply.filters);
    const {notAllowed} = useCheckAbilities();
    const emptyValuePlaceHolder = '---';
    const [selectedStates, setSelectedStates] = useState<string[]>(Object.keys(statusTextValues));
    const [checkAllStatus, setCheckAllStatus] = useState(false);
    let previousColor: string, prevItemBackgroundColor: string;
    const [showMenuBadge] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        dispatch(fetchGetOrdersConfirm(token, value, limit, filters, selectedStates));
    };
    const handleChangeRowsPerPage = (event: any) => {
        dispatch(fetchGetOrdersConfirm(token, 1, event.target.value, filters, selectedStates));
    };
    const schemaFormModal = yup.object({
        dateIni: yup.date().required('Fecha inicio requerido'),
        dateEnd: yup.date().required('Fecha final requerido'),
        year: yup.string(),
        status: yup.string(),
        societyName: yup.string(),
        purchaseOrder: yup.string(),
        society: yup.string(),
        entrySheet: yup.string(),
    });

    const {handleSubmit, control, watch, setValue} = useForm<IFormDate>({
        resolver: yupResolver(schemaFormModal),
    });
    const watchForm = watch();
    const onSubmit = () => {
        const json = {
            dateIni: DateTime.fromISO(watchForm.dateIni.toISOString()).toFormat('yyyy-MM-dd'),
            dateEnd: DateTime.fromISO(watchForm.dateEnd.toISOString()).toFormat('yyyy-MM-dd'),
            year: watchForm.year,
            status: watchForm.status ?? selectedStates.join(','),
            society: watchForm.society,
            societyName: watchForm.societyName,
            purchaseOrder: watchForm.purchaseOrder,
            entrySheet: watchForm.entrySheet,
        };
        dispatch(postOrderFilters(json));
        dispatch(fetchGetOrdersConfirm(token, currentPage, limit, json, selectedStates));
    };
    const handleChangeDownload = (format: any) => {
        dispatch(fetchGetDownloadOrdersDetail(token, format, filters));
    };
    useEffect(() => {
        if (token) {
            const json = {
                dateIni: DateTime.now().startOf('month').toFormat('yyyy-MM-dd'),
                dateEnd: DateTime.now().toFormat('yyyy-MM-dd'),
                year: watchForm.year ?? DateTime.now().year,
                status: watchForm.status ?? selectedStates.join(','),
            };
            dispatch(postOrderFilters(json));
            dispatch(fetchGetOrdersConfirm(token, currentPage, limit, json, selectedStates));
        }
    }, [dispatch, token]);

    const handleChangeStatus = (event: any) => {
        const {
            target: {value},
        } = event;
        cleanFilterStatus(value);
    };

    const hanldeCheckAllStatus = () => {
        setCheckAllStatus((prev) => {
            cleanFilterStatus(!prev ? Object.keys(statusTextValues) : []);
            return !prev;
        });
    };

    const cleanFilterStatus = (value: any) => {
        const selectedStatus =
            typeof value === 'string'
                ? value.split(',')
                : value.filter((item: string) => !item.includes('notCheck'));
        setSelectedStates(selectedStatus);
        let newArray = [];
        if (selectedStatus.length > 0) {
            newArray = selectedStatus.map((item: string) => {
                return Object.keys(statusTextValues).find((e: string) => e === item);
            });
        }
        setValue('status', newArray.length > 0 ? newArray.join(',') : '');
    };

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '15px 23px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '10px 0',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '24px',
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 400,
                    }}
                >
                    {t('confirmposimply.title')}
                </Typography>
            </Box>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Can
                    I={ConfirmDeliveryScheduleInvoiceEnum.FILTER}
                    a={FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '15px',
                            background: '#fff',
                            gap: '10px',
                        }}
                    >
                        <Can
                            I={ConfirmDeliveryScheduleInvoiceEnum.FIND_SUPPLIER}
                            a={FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE}
                        >
                            <Controller
                                name="society"
                                control={control}
                                render={({field: {onChange, value}}) => {
                                    return (
                                        <TextField
                                            value={value}
                                            onChange={onChange}
                                            label={t('confirmposimply.filters.society')}
                                            size="small"
                                        />
                                    );
                                }}
                            />
                            <Controller
                                name="societyName"
                                control={control}
                                render={({field: {onChange, value}}) => {
                                    return (
                                        <TextField
                                            value={value}
                                            onChange={onChange}
                                            label={t('confirmposimply.filters.society_name')}
                                            size="small"
                                        />
                                    );
                                }}
                            />
                        </Can>
                        <Can
                            I={ConfirmDeliveryScheduleInvoiceEnum.FIND_PO}
                            a={FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE}
                        >
                            <Controller
                                name="purchaseOrder"
                                control={control}
                                render={({field: {onChange, value}}) => {
                                    return (
                                        <TextField
                                            value={value}
                                            onChange={onChange}
                                            label={t('consultpo.table.headers.purchase_order')}
                                            size="small"
                                        />
                                    );
                                }}
                            />
                        </Can>
                        <Controller
                            name="entrySheet"
                            control={control}
                            render={({field: {onChange, value}}) => {
                                return (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        label={t('receptpo.filters.entry_sheet')}
                                        size="small"
                                    />
                                );
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '15px',
                            background: '#fff',
                            gap: '10px',
                        }}
                    >
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                            <Controller
                                name="dateIni"
                                control={control}
                                defaultValue={DateTime.now().startOf('month').toJSDate()}
                                render={({field: {onChange, value}}) => (
                                    <DatePicker
                                        label={t('logbook_and_details.start_date')}
                                        value={value}
                                        onChange={onChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                            />
                                        )}
                                        maxDate={watchForm.dateEnd}
                                        minDate={
                                            new Date(
                                                new Date().setFullYear(new Date().getFullYear() - 1)
                                            )
                                        }
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                            <Controller
                                name="dateEnd"
                                control={control}
                                defaultValue={new Date()}
                                render={({field: {onChange, value}}) => (
                                    <DatePicker
                                        label={t('logbook_and_details.end_date')}
                                        value={value}
                                        onChange={onChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                            />
                                        )}
                                        minDate={watchForm.dateIni}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        <Controller
                            name="year"
                            control={control}
                            defaultValue={new Date().getFullYear()}
                            render={({field, fieldState}) => (
                                <FormControl sx={{marginLeft: '10px', height: '40px'}}>
                                    <InputLabel>{t('components.year_selector.title')}</InputLabel>
                                    <Select
                                        label="Motivo de Rechazo"
                                        {...field}
                                        error={!!fieldState.error}
                                        sx={{width: '100px', height: '40px'}}
                                    >
                                        <MenuItem value={2025}>2025</MenuItem>
                                        <MenuItem value={2024}>2024</MenuItem>
                                        <MenuItem value={2023}>2023</MenuItem>
                                        <MenuItem value={2022}>2022</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        />
                        <Controller
                            render={({field, fieldState}) => (
                                <FormControl>
                                    <InputLabelRol>
                                        {t('components.status_selector.title')}{' '}
                                    </InputLabelRol>
                                    <StyledSelect
                                        {...field}
                                        multiple
                                        value={selectedStates}
                                        onChange={handleChangeStatus}
                                        input={<OutlinedInput label="Tag" />}
                                        renderValue={(selected: any) =>
                                            processString(selected.join(','), t)
                                        }
                                        MenuProps={{
                                            sx: {
                                                '&& .Mui-selected': {
                                                    backgroundColor: '#FFFF',
                                                },
                                                '&:hover': {
                                                    backgroundColor: 'transparent',
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem
                                            value={'notCheck'}
                                            onClick={() => {
                                                setTimeout(() => {
                                                    hanldeCheckAllStatus();
                                                }, 500);
                                            }}
                                        >
                                            <Checkbox
                                                checked={checkAllStatus}
                                                sx={{
                                                    '&.Mui-checked': {
                                                        color: theme.palette.primary.main,
                                                    },
                                                }}
                                            />
                                            <ListItemText
                                                primary={t(`consultpo.filters.select_all`)}
                                            />
                                        </MenuItem>
                                        {Object.keys(statusTextValues)
                                            .filter((name) => {
                                                if (userType === 'SUPPLIER') {
                                                    return websiteType !== 'FOREIGN'
                                                        ? name !== 'INVOICE_IN_VALIDATION'
                                                        : name !== 'BILL_VALIDATED';
                                                }
                                                return true;
                                            })
                                            .map((key: string, index: number) => (
                                                <MenuItem key={index} value={key}>
                                                    <Checkbox
                                                        checked={selectedStates.indexOf(key) > -1}
                                                        sx={{
                                                            '&.Mui-checked': {
                                                                color: theme.palette.primary.main,
                                                            },
                                                        }}
                                                    />
                                                    <ListItemText
                                                        primary={t(
                                                            `modelorama.table.status.${statusTextValues[key]}`
                                                        )}
                                                    />
                                                </MenuItem>
                                            ))}
                                    </StyledSelect>
                                </FormControl>
                            )}
                            control={control}
                            name="status"
                        />
                        <Tooltip title={t('components.filter_btn.title')} placement="bottom" arrow>
                            <Button
                                type="submit"
                                aria-label="submit"
                                color="secondary"
                                variant="outlined"
                                sx={{
                                    minWidth: '40px',
                                    height: '36px',
                                    padding: '5px 5px',
                                    marginLeft: '10px',
                                }}
                                disabled={notAllowed(
                                    FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE,
                                    ConfirmDeliveryScheduleInvoiceEnum.FILTER
                                )}
                            >
                                <Search />
                            </Button>
                        </Tooltip>
                        <Box sx={{flex: '1 1 auto'}} />
                        <Tooltip title={t('options')} placement="bottom" arrow>
                            <Badge invisible={!showMenuBadge} color="primary" badgeContent="">
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleOpenMenu}
                                    id="menu-button"
                                    aria-controls={openMenu ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openMenu ? 'true' : undefined}
                                    sx={{
                                        minWidth: '40px',
                                        height: '36px',
                                        padding: '5px 5px',
                                        marginLeft: '10px',
                                    }}
                                >
                                    <MenuIcon />
                                </Button>
                            </Badge>
                        </Tooltip>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openMenu}
                            onClose={handleCloseMenu}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuList>
                                <MenuItem
                                    onClick={() => {
                                        window.location.href = '/confirmposimply';
                                        handleCloseMenu();
                                    }}
                                >
                                    <ListItemIcon>
                                        <VisibilityOutlined fontSize="medium" />
                                    </ListItemIcon>
                                    <ListItemText>{t('simple_view')}</ListItemText>
                                </MenuItem>
                                <Can
                                    I={ConfirmDeliveryScheduleInvoiceEnum.EXPORT_EXCEL_CSV}
                                    a={FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE}
                                >
                                    <Divider />
                                    <MenuItem
                                        onClick={() => {
                                            handleChangeDownload('csv');
                                            handleCloseMenu();
                                        }}
                                        disabled={notAllowed(
                                            FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE,
                                            ConfirmDeliveryScheduleInvoiceEnum.EXPORT_EXCEL_CSV
                                        )}
                                    >
                                        <ListItemIcon>
                                            <DescriptionOutlined fontSize="medium" />
                                        </ListItemIcon>
                                        <ListItemText>
                                            {t('components.download_csv_btm.title')}
                                        </ListItemText>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            handleChangeDownload('xlsx');
                                            handleCloseMenu();
                                        }}
                                        disabled={notAllowed(
                                            FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE,
                                            ConfirmDeliveryScheduleInvoiceEnum.EXPORT_EXCEL_CSV
                                        )}
                                    >
                                        <ListItemIcon>
                                            <InsertDriveFileOutlined fontSize="medium" />
                                        </ListItemIcon>
                                        <ListItemText>
                                            {t('components.download_xlsx_btm.title')}
                                        </ListItemText>
                                    </MenuItem>
                                </Can>
                            </MenuList>
                        </Menu>
                    </Box>
                </Can>
            </Box>
            <TableContainerDetailOrder container>
                <TableHeadRow container>
                    {/* Primera fila */}
                    <TableCellHeadDetail sx={{fontSize: '9.5px'}}>
                        {t('consultpo.table.headers.purchase_order')}
                    </TableCellHeadDetail>
                    <TableCellHeadDetail sx={{fontSize: '9.5px'}}>
                        {t('confirmposimply.filters.society_name')}
                    </TableCellHeadDetail>
                    <TableCellHeadDetail sx={{fontSize: '9.5px'}}>RFC ABINBEV</TableCellHeadDetail>
                    <TableCellHeadDetail sx={{fontSize: '9.5px'}}>
                        {t('confirmposimply.entry_sheet_modal.position')}
                    </TableCellHeadDetail>
                    <TableCellHeadDetail sx={{fontSize: '9.5px'}}>
                        {t('unit_price')}
                    </TableCellHeadDetail>
                    <TableCellHeadDetail sx={{fontSize: '9.5px'}}>
                        {t('consultpo.table.headers.invoice_reference')}
                    </TableCellHeadDetail>
                    <TableCellHeadDetail sx={{fontSize: '9.5px'}}>
                        {t('confirmposimply.table.headers.invoice_date')}
                    </TableCellHeadDetail>
                    <TableCellHeadDetail sx={{fontSize: '9.5px'}}>
                        {t('consultpo.table.headers.net_amount')}
                    </TableCellHeadDetail>
                    <TableCellHeadDetail sx={{fontSize: '9.5px'}}>
                        {t('consultpo.table.headers.withholding_tax_amount')}
                    </TableCellHeadDetail>
                    <TableCellHeadDetail sx={{fontSize: '9.5px'}}>
                        {t('consultpo.table.headers.currency')}
                    </TableCellHeadDetail>
                    <TableCellHeadDetail sx={{fontSize: '9.5px'}}>
                        {t('consultpo.table.headers.entry_sheet_number')}
                    </TableCellHeadDetail>
                    <TableCellHeadDetail sx={{fontSize: '9.5px'}}>
                        {t('consultpo.table.headers.status')}
                    </TableCellHeadDetail>
                    <TableCellHeadDetail sx={{fontSize: '9.5px'}}>
                        {t('consultpo.table.headers.posting_date')}
                    </TableCellHeadDetail>
                    <TableCellHeadDetail sx={{fontSize: '9.5px'}}>
                        {t('consultpo.table.headers.rejection_category')}
                    </TableCellHeadDetail>
                    <TableCellHeadDetail sx={{fontSize: '9.5px'}}>
                        {t('consultpo.table.headers.parts')}
                    </TableCellHeadDetail>
                    <TableCellHeadDetail sx={{fontSize: '9.5px'}}>
                        {t('consultpo.table.headers.material_text_and_description')}
                    </TableCellHeadDetail>
                    <TableCellHeadDetail sx={{fontSize: '9.5px'}}>
                        {t('consultpo.table.headers.payment_date')}
                    </TableCellHeadDetail>
                </TableHeadRow>
                {loading ? (
                    // Muestra el esqueleto de carga mientras se cargan los datos
                    <>
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                    </>
                ) : (
                    binnacles.map((item: any, index: number) => {
                        let backgroundColor;
                        const hasProcessedStatus = checkProcessedStatus(item.frontStatus);

                        ({backgroundColor, previousColor, prevItemBackgroundColor} =
                            assignBackgroundColor(
                                binnacles[index - 1] ? binnacles[index - 1].orderFindId : null,
                                item.purchase_order_id,
                                previousColor,
                                prevItemBackgroundColor
                            ));

                        return (
                            <TableRow container sx={{backgroundColor: backgroundColor}} key={index}>
                                <TableCellDetail sx={{fontSize: '9.5px'}}>
                                    {item.external_id}
                                </TableCellDetail>
                                <TableCellDetail sx={{fontSize: '9.5px'}}>
                                    {item.society_name}
                                </TableCellDetail>
                                <TableCellDetail sx={{fontSize: '8.9px'}}>
                                    {hasProcessedStatus && item.receiver_rfc
                                        ? item.receiver_rfc
                                        : emptyValuePlaceHolder}
                                </TableCellDetail>
                                <TableCellDetail sx={{fontSize: '9.5px'}}>
                                    {item.position}
                                </TableCellDetail>
                                <TableCellDetail sx={{fontSize: '9.5px'}}>
                                    {item.unit_price &&
                                        Number(item.unit_price).toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        })}
                                </TableCellDetail>
                                <TableCellDetail sx={{fontSize: '9.5px'}}>
                                    {hasProcessedStatus
                                        ? item.bills[0]?.reference
                                        : emptyValuePlaceHolder}
                                </TableCellDetail>

                                <TableCellDetail sx={{fontSize: '9.5px'}}>
                                    {hasProcessedStatus && item.bills[0]?.date
                                        ? DateTime.fromISO(item.bills[0]?.date).toFormat(
                                              'dd/MM/yyyy'
                                          )
                                        : emptyValuePlaceHolder}
                                </TableCellDetail>
                                <TableCellDetail sx={{fontSize: '9.5px'}}>
                                    {item.bills[0]?.amount &&
                                        item.bills[0]?.amount.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                            maximumFractionDigits: 2,
                                        })}
                                </TableCellDetail>
                                <TableCellDetail sx={{fontSize: '9.5px'}}>
                                    {hasProcessedStatus && item.withholdingAmount
                                        ? item.withholdingAmount.toLocaleString('en-US', {
                                              style: 'currency',
                                              currency: 'USD',
                                          })
                                        : emptyValuePlaceHolder}
                                </TableCellDetail>
                                <TableCellDetail sx={{fontSize: '9.5px'}}>
                                    {item.currency}
                                </TableCellDetail>

                                <TableCellDetail sx={{fontSize: '9.5px'}}>
                                    {item.entry_sheet_number}
                                </TableCellDetail>

                                <TableCellDetail sx={{fontSize: '9.5px'}}>
                                    <StatusText status={item.frontStatus} />
                                </TableCellDetail>
                                <TableCellDetail sx={{fontSize: '9.5px'}}>
                                    {hasProcessedStatus && item.postingDate //TODO
                                        ? DateTime.fromISO(item.postingDate).toFormat('dd/MM/yyyy')
                                        : emptyValuePlaceHolder}
                                </TableCellDetail>

                                <TableCellDetail sx={{fontSize: '9.5px'}}>
                                    {item.rejectedBy}
                                </TableCellDetail>

                                <TableCellDetail sx={{fontSize: '9.5px'}}>
                                    {item.quantity}
                                </TableCellDetail>

                                <TableCellDetail sx={{fontSize: '9.5px'}}>
                                    {item.textDescription}
                                </TableCellDetail>
                                <TableCellDetail sx={{fontSize: '9.5px'}}>
                                    {hasProcessedStatus && item.bills[0]?.payment_date
                                        ? DateTime.fromISO(item.bills[0]?.payment_date).toFormat(
                                              'dd/MM/yyyy'
                                          )
                                        : emptyValuePlaceHolder}
                                </TableCellDetail>
                            </TableRow>
                        );
                    })
                )}
            </TableContainerDetailOrder>
            <CustomPagination
                totalPages={totalPages}
                currentPage={currentPage}
                handleChange={handleChange}
                limit={limit}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Box>
    );
};
OrderConfirm.acl = {
    action: ConfirmDeliveryScheduleInvoiceEnum.VIEW_LIST,
    subject: FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE,
};
export default OrderConfirm;
