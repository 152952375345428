import {
    GET_NOTIFICATION,
    GET_NOTIFICATION_ERROR,
    GET_NOTIFICATION_SUCCESS,
    NotificationItemType,
    NotificationReduceType,
    POST_NOTIFICATION,
    POST_NOTIFICATION_ERROR,
    ADD_NOTIFICATION,
} from '@components/notifications/redux/type/notification-type';
const initialState: NotificationReduceType = {
    notifications: [],
    loading: false,
    error: false,
};

const notificationReducers = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_NOTIFICATION:
            return {
                ...state,
                loading: true,
            };
        case GET_NOTIFICATION_SUCCESS:
            return {
                ...state,
                notifications: action.value.data
                    ? action.value.data.filter(
                          ({notification, id}: NotificationItemType) =>
                              notification.isRead === false
                      )
                    : [],

                loading: false,
            };
        case GET_NOTIFICATION_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };

        case POST_NOTIFICATION:
            return {
                ...state,
                loading: false,
            };
        case POST_NOTIFICATION_ERROR:
            return {
                ...state,
                error: action.value,
                loading: false,
            };
        case ADD_NOTIFICATION:
            state.notifications.push(action.value);
            return {
                ...state,
                notifications: state.notifications,
                loading: false,
            };
        default:
            return state;
    }
};

export default notificationReducers;
