'use client';
import {
    fetchPostFilesAgents,
    nextStepUploadFilesAgents,
} from '@/components/orderSimply/redux/actions/OrderAgentsActions';
import {ButtonCancel, Loader, SaveButton} from '@/components/orderSimply/Styled';
import {TypographyStyled600} from '@/components/UI/atoms/modals/Styled';
import {AppDispatch, RootState} from '@/config/store';
import {CheckCircleOutline, ErrorOutlineOutlined, RequestPageOutlined} from '@mui/icons-material';
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Skeleton,
    Typography,
} from '@mui/material';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

export type ValidateXmlInvoicesAgentsProps = {
    closeModal: () => void;
    happyPath: boolean;
};

interface responseValidation {
    invoice: string;
    errors: Record<string, any>;
}
const ValidateXmlInvoicesAgents: React.FC<ValidateXmlInvoicesAgentsProps> = ({
    closeModal,
    happyPath,
}) => {
    const {t} = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const loading = useSelector((state: RootState) => state.orderssimply.loading);
    const xmlsOrderAgents = useSelector((state: RootState) => state.orderssimply.xmlsOrderAgents);
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const filesOrderAgents = useSelector((state: RootState) => state.orderssimply.filesOrderAgents);
    const expendAccount = useSelector((state: RootState) => state.orderssimply.expendAccount);

    const hasErrors = () => {
        return xmlsOrderAgents.some(
            ({errors}: responseValidation) => Object.keys(errors).length > 0
        );
    };
    const onSubmit = () => {
        dispatch(
            fetchPostFilesAgents(token, {
                file: filesOrderAgents,
                expenseAccount: expendAccount,
            })
        );
    };
    useEffect(() => {
        if (xmlsOrderAgents.length > 0 && (!hasErrors() || happyPath)) {
            dispatch(nextStepUploadFilesAgents());
        }
    }, [happyPath, xmlsOrderAgents]);
    return (
        <>
            <Box
                display="flex"
                p={1}
                mt={2}
                flexDirection={'column'}
                sx={{justifyContent: 'space-between'}}
                minWidth={'600px'}
            />
            <Box
                sx={{
                    display: 'flex',
                    border: '2px solid #c5d1d8',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '10px 0px',
                    paddingTop: '130px',
                    height: '300px',
                    overflowX: 'auto',
                }}
            >
                {xmlsOrderAgents.length > 0 &&
                    xmlsOrderAgents.map(({invoice}: responseValidation) => (
                        <Box
                            key={invoice}
                            sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                        >
                            <RequestPageOutlined fontSize="large" />
                            <Typography variant="subtitle2">{invoice}</Typography>
                        </Box>
                    ))}
            </Box>
            <TypographyStyled600>{t('uploadComplement.validation_summary')}</TypographyStyled600>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                }}
            >
                <List
                    sx={{
                        border: '2px solid #c5d1d8',
                        width: '100%',
                        position: 'relative',
                        overflow: 'auto',
                        maxHeight: 200,
                        '& ul': {padding: 0},
                        margin: '10px 0px',
                        padding: '0px',
                    }}
                    subheader={<li />}
                    dense
                >
                    {loading && (
                        <li key={`section-`}>
                            <ul>
                                <ListSubheader
                                    sx={{
                                        borderTop: '1px solid #c5d1d8',
                                        borderBottom: '1px solid #c5d1d8',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            height: '48px',
                                            padding: '16px 0px',
                                        }}
                                    >
                                        <Skeleton sx={{fontSize: '1rem', width: '200px'}} />
                                    </Box>
                                </ListSubheader>
                                {[0, 1].map((item) => (
                                    <ListItem key={`item-0-${item}`}>
                                        <ListItemIcon>
                                            <Skeleton variant="circular">
                                                <Box sx={{width: '24px', height: '24px'}} />
                                            </Skeleton>
                                        </ListItemIcon>
                                        <Skeleton
                                            variant="text"
                                            sx={{fontSize: '1rem', width: '300px'}}
                                        />
                                    </ListItem>
                                ))}
                            </ul>
                        </li>
                    )}
                    {!loading &&
                        xmlsOrderAgents.length > 0 &&
                        xmlsOrderAgents.map(
                            ({invoice, errors}: {invoice: string; errors: Record<string, any>}) => (
                                <li key={`section-${invoice}`}>
                                    <ul>
                                        <ListSubheader
                                            sx={{
                                                borderTop: '1px solid #c5d1d8',
                                                borderBottom: '1px solid #c5d1d8',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                }}
                                            >
                                                {t(invoice)}
                                                <Box sx={{flex: '1 1 auto'}} />
                                                {`${Object.values(errors).length} ${t('errors')}`}
                                            </Box>
                                        </ListSubheader>
                                        {Object.values(errors).map((item: string) => (
                                            <ListItem key={`item-${invoice}-${item}`}>
                                                <ListItemIcon>
                                                    <ErrorOutlineOutlined color="error" />
                                                </ListItemIcon>

                                                <ListItemText
                                                    primary={`${t('poagents.error_types.' + item)}`}
                                                />
                                            </ListItem>
                                        ))}
                                    </ul>
                                </li>
                            )
                        )}
                    {!loading && xmlsOrderAgents.length === 0 && (
                        <ListItem>
                            <ListItemIcon>
                                <CheckCircleOutline color="success" />
                            </ListItemIcon>
                            <ListItemText
                                primary={t('uploadComplement.complement_without_errors')}
                            />
                        </ListItem>
                    )}
                </List>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                }}
            >
                <ButtonCancel onClick={closeModal}>{t('cancel').toUpperCase()}</ButtonCancel>
                {!hasErrors() && (
                    <SaveButton disabled={loading} onClick={onSubmit} sx={{marginRight: '8px'}}>
                        {loading ? <Loader size={24} /> : t('view_details').toUpperCase()}
                    </SaveButton>
                )}
            </Box>
        </>
    );
};

export default ValidateXmlInvoicesAgents;
