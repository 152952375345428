import SkeletonRow from '@/components/UI/molecules/tables/SkeletonRow'; // Asegúrate de proporcionar la ruta correcta
import {
    TableCell,
    TableCellHead,
    TableCellHeadFinal,
    TableCellHeadInitial,
    TableCellIcons,
    TableContainerBinnance,
    TableHeadRow,
} from '@/components/UI/molecules/tables/TablePrincipal';
import {FeatureCodeEnum, LogDetailsEnum} from '@/enums/permissions.enum';
import useCheckAbilities from '@/hooks/useCheckAbilities';
import AvatarWithName from '@components/UI/molecules/avatar/AvatarWithName';
import {
    TextButton,
    TextButtonDetails,
    TextError,
    TextSuccess,
} from '@components/UI/molecules/textButton/textButtons';
import CustomPagination from '@components/UI/organisms/pagination/Pagination';
import {getLastModuleRender} from '@components/configuration/redux/actions/ConfigurationActions';
import {TypographyStyledUser} from '@components/faqs/StyledFAQ';
import {
    fetchGetBinnacles,
    fetchGetBinnaclesDownload,
    getBinnaclesDetailsName,
} from '@components/log/redux/actions/BinnaclesActions';
import IconDownload from '@components/manuals/images/IconDownload.svg';
import {AppDispatch, RootState} from '@config/store';
import {yupResolver} from '@hookform/resolvers/yup';
import {IFormDate, ITable} from '@log/interface';
import {Box, Grid, Typography, useTheme} from '@mui/material';
import TextField from '@mui/material/TextField';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {enUS, es} from 'date-fns/locale';
import {DateTime} from 'luxon';
import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';
const DEFAULT_PAGE = 1;
const Binnacles: React.FC = () => {
    const {t} = useTranslation();
    const userLang = localStorage.getItem('user-lang') || 'es';
    const lang = userLang === 'es' ? es : enUS;
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const binnacles = useSelector((state: RootState) => state.binnacles.binnacles);
    const currentPage = useSelector((state: RootState) => state.binnacles.page);
    const limit = useSelector((state: RootState) => state.binnacles.limit);
    const typeUsers = useSelector((state: RootState) => state.binnacles.typeUsers);
    const totalPages = useSelector((state: RootState) => state.binnacles.totalPages);
    const loading = useSelector((state: RootState) => state.binnacles.loading);
    const {notAllowed} = useCheckAbilities();
    const schemaFormModal = yup.object({
        dateIni: yup.date().required('Fecha inicio requerido'),
        dateEnd: yup.date().required('Fecha final requerido'),
        user: yup.string(),
    });

    const {handleSubmit, control, watch} = useForm<IFormDate>({
        resolver: yupResolver(schemaFormModal),
    });
    const watchForm = watch();
    const formatDateIni = new Date(watchForm.dateIni);
    const formatDateEnd = new Date(watchForm.dateEnd);
    const json = {
        dateIni: DateTime.fromJSDate(formatDateIni).toFormat('MM-dd-yyyy'),
        dateEnd: DateTime.fromJSDate(formatDateEnd).toFormat('MM-dd-yyyy'),
    };
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        const user = watchForm.user ?? '';
        dispatch(fetchGetBinnacles(token, value, limit, {...json, user}));
    };
    const handleChangeRowsPerPage = (event: any) => {
        const user = watchForm.user ?? '';
        dispatch(fetchGetBinnacles(token, 1, event.target.value, {...json, user}));
    };

    const onSubmit = () => {
        const user = watchForm.user ?? '';
        dispatch(fetchGetBinnacles(token, currentPage, limit, {...json, user}));
    };
    const handleChangeDownload = (format: any) => {
        const formatDateIni = new Date(watchForm.dateIni);
        const formatDateEnd = new Date(watchForm.dateEnd);
        const json = {
            dateIni: DateTime.fromJSDate(formatDateIni).toFormat('MM-dd-yyyy'),
            dateEnd: DateTime.fromJSDate(formatDateEnd).toFormat('MM-dd-yyyy'),
            userName: watchForm.user ?? '',
        };
        dispatch(fetchGetBinnaclesDownload(token, format, json));
    };
    useEffect(() => {
        if (token) {
            const json = {
                dateIni: DateTime.now().startOf('month').toFormat('MM-dd-yyyy'),
                dateEnd: DateTime.now().toFormat('MM-dd-yyyy'),
                user: watchForm.user ?? '',
            };

            dispatch(fetchGetBinnacles(token, DEFAULT_PAGE, limit, json));
        }
    }, [dispatch, token]);

    const getLastDateAccess = (moment: string) => {
        const [month, day, year] = moment.split('/');
        return `${month}/${day}/${year}`;
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '15px 23px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '10px 0',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '24px',
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 400,
                    }}
                >
                    {t('logbook_and_details.title')}
                </Typography>
            </Box>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Box
                    sx={{
                        display: 'flex',
                        marginBottom: '20px',
                        padding: '15px',
                        height: '73px',
                        width: '100%',
                        background: '#fff',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box sx={{display: 'flex', alignItems: 'flex-end', gap: '5px'}}>
                        <Controller
                            name="user"
                            control={control}
                            render={({field: {onChange, value}}) => {
                                return (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        label={t('user')}
                                        size="small"
                                    />
                                );
                            }}
                        />
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                            <Controller
                                name="dateIni"
                                control={control}
                                defaultValue={DateTime.now().startOf('month').toJSDate()}
                                render={({field: {onChange, value}}) => (
                                    <DatePicker
                                        label={t('logbook_and_details.start_date')}
                                        value={value}
                                        onChange={onChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                            />
                                        )}
                                        maxDate={new Date()}
                                        disabled={notAllowed(
                                            FeatureCodeEnum.LOG_DETAILS,
                                            LogDetailsEnum.FILTER_DATE
                                        )}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                            <Controller
                                name="dateEnd"
                                control={control}
                                defaultValue={DateTime.now().toJSDate()}
                                render={({field: {onChange, value}}) => (
                                    <DatePicker
                                        label={t('logbook_and_details.end_date')}
                                        value={value}
                                        onChange={onChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                            />
                                        )}
                                        minDate={watchForm.dateIni}
                                        maxDate={DateTime.now().toJSDate()}
                                        disabled={notAllowed(
                                            FeatureCodeEnum.LOG_DETAILS,
                                            LogDetailsEnum.FILTER_DATE
                                        )}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        <TextButton
                            disabled={notAllowed(
                                FeatureCodeEnum.LOG_DETAILS,
                                LogDetailsEnum.CONSULT
                            )}
                        >
                            {t('components.filter_btn.title').toUpperCase()}
                        </TextButton>
                    </Box>
                    {loading || typeUsers === null ? (
                        // Muestra el esqueleto de carga mientras se cargan los datos
                        <></>
                    ) : (
                        <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: 600,
                                        fontFamily: theme.typography.fontFamily,
                                    }}
                                >
                                    {t('logbook_and_details.total_internal_users')}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '13px',
                                        fontWeight: 500,
                                        fontFamily: theme.typography.fontFamily,
                                        color: '#515151',
                                    }}
                                >
                                    {typeUsers.countInternals}
                                </Typography>
                            </Box>
                            <Box
                                sx={{display: 'flex', flexDirection: 'column', paddingLeft: '30px'}}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: 600,
                                        fontFamily: theme.typography.fontFamily,
                                    }}
                                >
                                    {t('logbook_and_details.total_supplier_users')}
                                </Typography>

                                <Typography
                                    sx={{
                                        fontSize: '13px',
                                        fontWeight: 500,
                                        fontFamily: theme.typography.fontFamily,
                                        color: '#515151',
                                    }}
                                >
                                    {typeUsers.countExternals}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                    <Box sx={{display: 'flex', alignItems: 'flex-end', gap: '10px'}}>
                        <TextButtonDetails
                            onClick={() => {
                                handleChangeDownload('csv');
                            }}
                            disabled={notAllowed(
                                FeatureCodeEnum.LOG_DETAILS,
                                LogDetailsEnum.EXPORT_EXCEL_CSV
                            )}
                        >
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <img src={IconDownload} />
                                <TypographyStyledUser sx={{marginLeft: '5px'}}>
                                    CSV
                                </TypographyStyledUser>
                            </div>
                        </TextButtonDetails>
                        <TextButtonDetails
                            onClick={() => {
                                handleChangeDownload('xlsx');
                            }}
                            disabled={notAllowed(
                                FeatureCodeEnum.LOG_DETAILS,
                                LogDetailsEnum.EXPORT_EXCEL_CSV
                            )}
                        >
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <img src={IconDownload} />
                                <TypographyStyledUser sx={{marginLeft: '5px'}}>
                                    XLSX
                                </TypographyStyledUser>
                            </div>
                        </TextButtonDetails>
                    </Box>
                </Box>
            </Box>
            <TableContainerBinnance container width={'calc(100vw - 330px)'}>
                <TableHeadRow container>
                    {/* Primera fila */}
                    <Grid item xs={3} sx={{boxShadow: 0}}>
                        <TableCellHeadInitial>{t('user')}</TableCellHeadInitial>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead>{t('ticket.create_date')}</TableCellHead>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHead>{t('logbook_and_details.last_access_date')}</TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead>{t('hour')}</TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead>{t('country')}</TableCellHead>
                    </Grid>

                    <Grid item xs={1}>
                        <TableCellHead>{t('type')}</TableCellHead>
                    </Grid>
                    <Grid item xs={2}>
                        <TableCellHeadFinal />
                    </Grid>
                </TableHeadRow>
                {loading ? (
                    // Muestra el esqueleto de carga mientras se cargan los datos
                    <>
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                    </>
                ) : (
                    binnacles.map((item: ITable) => (
                        <>
                            <Grid item xs={3}>
                                <TableCell>
                                    <AvatarWithName
                                        name={item.fullName !== '' ? item.fullName : item.email}
                                    />
                                </TableCell>
                            </Grid>

                            <Grid item xs={2}>
                                <TableCell>
                                    {DateTime.fromISO(item.createdAt).toFormat('dd/MM/yyyy')}
                                </TableCell>
                            </Grid>
                            <Grid item xs={2}>
                                <TableCell>{getLastDateAccess(item.lastDateAccess)}</TableCell>
                            </Grid>
                            <Grid item xs={1}>
                                <TableCell>
                                    {DateTime.fromISO(item.lastHourAccess).toFormat('HH:mm:ss')}
                                </TableCell>
                            </Grid>
                            <Grid item xs={1}>
                                <TableCell>{item.nameCountry} </TableCell>
                            </Grid>

                            <Grid item xs={1}>
                                <TableCell>
                                    {item.userType === 'SUPPLIER' ? (
                                        <TextError sx={{fontSize: '13px'}}>
                                            {t(`logbook_and_details.type_role.${item.userType}`)}
                                        </TextError>
                                    ) : (
                                        <TextSuccess sx={{fontSize: '13px'}}>
                                            {t(`logbook_and_details.type_role.${item.userType}`)}
                                        </TextSuccess>
                                    )}
                                </TableCell>
                            </Grid>
                            <Grid item xs={2}>
                                <TableCellIcons>
                                    <TextButtonDetails
                                        onClick={() => {
                                            dispatch(getLastModuleRender('binnacles'));
                                            dispatch(
                                                getBinnaclesDetailsName(
                                                    item.typeRole,
                                                    item.fullName,
                                                    item.id
                                                )
                                            );
                                        }}
                                    >
                                        {t('view_details')}
                                    </TextButtonDetails>
                                </TableCellIcons>
                            </Grid>
                        </>
                    ))
                )}
            </TableContainerBinnance>
            <CustomPagination
                totalPages={totalPages}
                currentPage={currentPage}
                handleChange={handleChange}
                limit={limit}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Box>
    );
};
export default Binnacles;
