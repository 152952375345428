/* eslint-disable */
export enum OrderStatusEnum {
    BILL_VALIDATED = 'BILL_VALIDATED',
    CLOSED = 'CLOSED',
    CONFIRMED = 'CONFIRMED',
    CREATED = 'CREATED',
    DELIVERED = 'DELIVERED',
    INVOICE_IN_PROCESS = 'INVOICE_IN_PROCESS',
    PAID = 'PAID',
    PARTIALLY_DELIVERED = 'PARTIALLY_DELIVERED',
    PROCESS_PENDING = 'PROCESS_PENDING',
    PROGRAMMED_TO_PAY = 'PROGRAMMED_TO_PAY',
    REJECTED = 'REJECTED',
    REJECTED_INVOICE = 'REJECTED_INVOICE',
    SCHEDULED_TO_PAY = 'SCHEDULED_TO_PAY',
    GOODS_RETURN = 'GOODS_RETURN',
}
/* eslint-enable */

export function checkProcessedStatus(status: OrderStatusEnum) {
    return [
        OrderStatusEnum.BILL_VALIDATED,
        OrderStatusEnum.PROCESS_PENDING,
        OrderStatusEnum.INVOICE_IN_PROCESS,
        OrderStatusEnum.SCHEDULED_TO_PAY,
        OrderStatusEnum.CLOSED,
        OrderStatusEnum.PAID,
        OrderStatusEnum.PROGRAMMED_TO_PAY,
        OrderStatusEnum.REJECTED_INVOICE,
        OrderStatusEnum.REJECTED,
        OrderStatusEnum.DELIVERED,
    ].includes(status);
}

// TODO: Create a hash table.

export function isPurchaseOrderDelivered(status: OrderStatusEnum) {
    return status === OrderStatusEnum.DELIVERED;
}
export function allowedChecked(status: OrderStatusEnum) {
    const checkAllowed = [
        OrderStatusEnum.CREATED,
        OrderStatusEnum.DELIVERED,
        OrderStatusEnum.BILL_VALIDATED,
        OrderStatusEnum.CONFIRMED,
        OrderStatusEnum.PARTIALLY_DELIVERED,
        OrderStatusEnum.REJECTED_INVOICE,
    ];
    return checkAllowed.includes(status);
}

export function isPurchaseOrderBillValidated(status: OrderStatusEnum) {
    return status === OrderStatusEnum.BILL_VALIDATED;
}

export function isPurchaseOrderBillConfirmed(status: OrderStatusEnum) {
    return status === OrderStatusEnum.CONFIRMED;
}

export function isPurchaseOrderBillPartiallyDelivereded(status: OrderStatusEnum) {
    return status === OrderStatusEnum.PARTIALLY_DELIVERED;
}
