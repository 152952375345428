// FF stands for Feature Flag
/* eslint-disable */
import {parseBoolean} from '@utils/parse-boolean.constant';

const trueValues = [true, 'true', 'TRUE', 'True', undefined];
export const FF_SHOW_PO_DETAILS = trueValues.includes(
    process.env.REACT_APP_FF_CONFIRMPOACTION_SHOW_PO_DETAILS
);
export const FF_ENABLE_TERMS_AND_CONDITIONS = parseBoolean(
    process.env.REACT_APP_FF_ENABLE_TERMS_AND_CONDITIONS ?? 'false'
);

export const isEnabledTermsAndConditions = FF_ENABLE_TERMS_AND_CONDITIONS === true;

export const MAX_FILE_SIZE_MB_MANUALS = Number(
    process.env.REACT_APP_MAX_FILE_SIZE_MB_LIMIT_MANUALS ?? 10
);
export const ORDERS_URL = Number(process.env.REACT_APP_ORDERS_URL ?? 'order/');
