import DownloadFile from '@/components/orderSimply/components/DownloadFile';
import GridTableCell from '@/components/orderSimply/components/GridTableCell';
import {IBillNew, IItemNew} from '@/components/orderSimply/interfaces/item-new.interface';
import {selectChecboxAllowedStatus} from '@/components/orderSimply/utils/constants';
import {
    allowedChecked,
    checkProcessedStatus,
    isPurchaseOrderBillConfirmed,
    isPurchaseOrderBillPartiallyDelivereded,
    isPurchaseOrderBillValidated,
    isPurchaseOrderDelivered,
    OrderStatusEnum,
} from '@/components/orderSimply/utils/orderConfirmHelpers';
import StatusText from '@/components/UI/atoms/buttons/StatusText';
import {TableCell, TableCellIcons} from '@/components/UI/molecules/tables/TablePrincipal';
import {Can} from '@/context/AbilityContext';
import {ConfirmDeliveryScheduleInvoiceEnum, FeatureCodeEnum} from '@/enums/permissions.enum';
import useCheckAbilities from '@/hooks/useCheckAbilities';
import IconConfirmOrderAction from '@components/UI/atoms/icons/IconConfirmOrderAction.svg';
import ArticleIcon from '@mui/icons-material/Article';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {Checkbox, Grid, styled, Tooltip, useTheme} from '@mui/material';
import {t} from 'i18next';
import {DateTime} from 'luxon';
import React from 'react';
import {useNavigate} from 'react-router-dom';

export const StyledTableCell = styled(TableCell)({
    fontSize: '9.5px',
    backgroundColor: 'transparent',
});

export interface IItem {
    //TODO: volver a tipar de acuerdo a lo recibido en el nuevo endpoint
    bill: any;
    billAmount: number;
    billingDate: string;
    billReference: string;
    billValidated: boolean;
    companyGroup: string;
    currency: string;
    entrySheet: string;
    externalId: string;
    iterations: number;
    lineItemId: string;
    message: string;
    orderFindId: string;
    orderId: string;
    paymentDate: string;
    pos: number;
    priceUnit: number;
    productName: string;
    remaining: number;
    societyName: string;
    status: OrderStatusEnum;
    materialType: string;
    orderType: string;
    paymentDetails: string;
    hasEntriesAndBills: boolean;
    bills: any[];
    goodsReceiptReference: string;
    websiteType: string;
}
const orderDownloadRestricted = [OrderStatusEnum.CREATED];

const LineItemRow = ({
    item,
    backgroundColor,
    selectedLineItems,
    handleLineItemSelection,
    handleDownloadOrder,
    showModalDetail,
    showModalEntryDetail,
    poType,
}: {
    item: any;
    backgroundColor: string;
    selectedLineItems: string[];
    handleLineItemSelection: (
        e: React.ChangeEvent<HTMLInputElement>,
        lineItemFilter: string,
        orderFindId: string,
        orderStatus: string,
        society_id: string,
        type: string
    ) => void;
    handleDownloadOrder: (item: IItemNew) => void;
    showModalDetail: (item: IItem) => void;
    showModalEntryDetail: (item: IItem) => void;
    poType: string;
}) => {
    const theme = useTheme();
    const {notAllowed, allowed} = useCheckAbilities();
    const emptyValuePlaceHolder = '---';
    const navigate = useNavigate();
    // const websiteType: CompanyTypeKey = useSelector(
    //     (state: RootState) => state.logins.user.company.websiteType
    // );
    const allowedBillStatuses = [
        'CREATED',
        'INVOICE_IN_PROCESS',
        'SCHEDULED_TO_PAY',
        'PAID',
        'CLOSE',
    ];
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // TODO: This should be refactored. Tag: LINE_ITEM_STATUS_TEMPORARY_SOLUTION
        const lineItemFilter = `${String(item.line_item_id)}-${String(item.entry_sheet_number)}`;
        handleLineItemSelection(
            e,
            lineItemFilter,
            item.purchase_order_id,
            item.frontStatus,
            item.society_id,
            item.type
        );
    };

    const handleDownloadClick = (item: IItemNew) => {
        if (
            allowed(
                FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE,
                ConfirmDeliveryScheduleInvoiceEnum.DOWNLOAD_PO
            )
        ) {
            handleDownloadOrder(item);
        }
    };

    const handleEntrySheetClick = () => {
        if (
            allowed(
                FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE,
                ConfirmDeliveryScheduleInvoiceEnum.VIEW_ENTRY_SHEET_DETAILS
            )
        ) {
            showModalDetail(item);
        }
    };

    const handleConfirmClick = () => {
        navigate(`/confirmpoaction/${item.purchase_order_id}/${item.company_group}`);
    };

    const lineItemFilterByPOStatus = isPurchaseOrderDelivered(item.frontStatus)
        ? `${String(item.line_item_id)}-${String(item.entry_sheet_number)}`
        : `${String(item.line_item_id)}-${String(item.entry_sheet_number)}`;

    const notAllowedStatus = !selectChecboxAllowedStatus.includes(item.frontStatus);
    const hasProcessedStatus = checkProcessedStatus(
        OrderStatusEnum[item.frontStatus as OrderStatusEnum]
    );
    const isServiceFo = item.material_type === 'SERVICE' && item.type === 'FO';
    const defaultValidation = notAllowedStatus && !isServiceFo;

    const isDisabled = () => {
        let response = false;

        if (poType === 'FO') {
            return response;
        }
        if (
            item.frontStatus === OrderStatusEnum.SCHEDULED_TO_PAY ||
            item.frontStatus === OrderStatusEnum.INVOICE_IN_PROCESS ||
            item.frontStatus === OrderStatusEnum.PAID
        ) {
            return true;
        }
        if (
            item.website_type === 'NATIONAL' &&
            item.frontStatus !== OrderStatusEnum.INVOICE_IN_PROCESS &&
            item.frontStatus !== OrderStatusEnum.REJECTED_INVOICE
        ) {
            return response;
        }
        if (item.website_type === 'FOREIGN') {
            return (
                item.frontStatus !== OrderStatusEnum.DELIVERED &&
                item.frontStatus !== OrderStatusEnum.REJECTED_INVOICE
            );
        }

        if (defaultValidation) {
            response = true;
        }
        if (isPurchaseOrderBillConfirmed(item.frontStatus)) {
            response = false;
        }
        return response;
    };

    const checked = () => {
        let response = false;
        if (item.frontStatus === OrderStatusEnum.GOODS_RETURN) {
            response = selectedLineItems.includes(lineItemFilterByPOStatus);
        }
        if (
            isPurchaseOrderDelivered(item.frontStatus) ||
            isPurchaseOrderBillPartiallyDelivereded(item.frontStatus)
        ) {
            response = selectedLineItems.includes(lineItemFilterByPOStatus);
        }

        if (
            isPurchaseOrderBillConfirmed(item.frontStatus) ||
            isPurchaseOrderBillValidated(item.frontStatus)
        ) {
            response = selectedLineItems.includes(lineItemFilterByPOStatus);
        }

        // Maintained original item.status validation for backward compatibility
        if (allowedChecked(item.status) || allowedChecked(item.order_status)) {
            response = selectedLineItems.includes(lineItemFilterByPOStatus);
        }

        if (isServiceFo) {
            response = selectedLineItems.includes(lineItemFilterByPOStatus);
        }

        return response;
    };

    const calculate2WMAmount = (bills: IBillNew[]) => {
        const invoiceMaps = bills
            .filter((bill) => allowedBillStatuses.includes(bill.status))
            /* se comento este codigo porque hace que no cuadren los montos,
               ya que existen facturas y recibos que no tienen ese campo */
            /*  .filter((bill) => bill.invoice_map?.length > 0)
            .map((bill) =>
                bill.invoice_map.find((invoice) => {
                    return invoice.lineItemId === item.line_item_id;
                })
            )*/
            .filter((invoice) => invoice !== undefined);

        const amount = invoiceMaps.reduce((acc: number, invoice: any) => {
            return acc + Number(invoice?.amount ?? 0);
        }, 0);

        return amount;
    };

    const itemAmountPaid =
        poType === 'FO'
            ? calculate2WMAmount(item.bills)
            : item.bills[item.bills.length - 1]?.amount;

    const getTranslateMessage = (message: string) => {
        const langKeys = {
            'Incomplete or Illegible Invoice': 'IncompleteOrIllegibleInvoice',
            'Invoice missing Company RFC': 'InvoiceMissingCompanyRFC',
            'Invoice from Previous Years': 'InvoiceFromPreviousYears',
            'Invoice in Different Currency vs Purchase Order': 'InvoiceInDifferentCurrency',
            'Invoice for Different Company vs Purchase Order': 'InvoiceForDifferentCompany',
            'Invoice to Different Supplier vs Purchase Order': 'InvoiceToDifferentSupplier',
            'Price Discrepancy': 'PriceDiscrepancy',
            'Invoice without Goods Receipt': 'InvoiceWithoutGoodsReceipt',
            'Duplicate Invoice': 'DuplicateInvoice',
            'Other reason, consult your buyer': 'OtherReasonConsultBuyer',
        };
        return langKeys[message as keyof typeof langKeys]
            ? t(`order_comfirm.${langKeys[message as keyof typeof langKeys]}`)
            : message;
    };

    const setFormatAmount = (tp: any, itemAmountPaid: any) => {
        const amount = Number(itemAmountPaid).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return tp === '161' ? <span style={{color: 'red'}}>-{amount}</span> : amount;
    };

    return (
        <div
            style={{
                width: '100%',
                backgroundColor: backgroundColor,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'normal',
            }}
        >
            <GridTableCell sx={{backgroundColor: 'transparent'}} xs={1}>
                <Checkbox
                    size="small"
                    onChange={handleCheckboxChange}
                    disabled={isDisabled()}
                    checked={checked()}
                />

                {!orderDownloadRestricted.includes(item.frontStatus) ? (
                    <DownloadFile
                        disabled={notAllowed(
                            FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE,
                            ConfirmDeliveryScheduleInvoiceEnum.DOWNLOAD_PO
                        )}
                        handleDownloadClick={() => handleDownloadClick(item)}
                        text={item.external_id}
                    />
                ) : (
                    item.external_id
                )}
                <ContentCopyIcon
                    style={{color: 'black', cursor: 'pointer', fontSize: '12px', marginLeft: '5px'}}
                    onClick={async () => await navigator.clipboard.writeText(item.external_id)}
                />
            </GridTableCell>
            <GridTableCell xs={0.5}>{item.society_id}</GridTableCell>
            <GridTableCell xs={1}>{item.society_name}</GridTableCell>
            <GridTableCell xs={1}>
                {hasProcessedStatus && item.bills[0]?.reference
                    ? item.bills[0]?.reference
                    : emptyValuePlaceHolder}
            </GridTableCell>
            <GridTableCell xs={1}>{item.product_name}</GridTableCell>
            <GridTableCell xs={0.5}>
                {Number(item.unit_price).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })}
            </GridTableCell>
            <GridTableCell xs={0.5}>{item.currency}</GridTableCell>
            <GridTableCell xs={1}>
                {item.entry_sheet_reference ? item.entry_sheet_reference : emptyValuePlaceHolder}
            </GridTableCell>
            <GridTableCell xs={1}>
                <a
                    style={{cursor: 'pointer', color: theme.palette.secondary.main}}
                    onClick={handleEntrySheetClick}
                >
                    {item.entry_sheet_number}
                </a>
            </GridTableCell>
            <GridTableCell xs={1}>
                {hasProcessedStatus && item.bills[0]?.date
                    ? DateTime.fromISO(item.bills[0]?.date).toFormat('dd/MM/yyyy')
                    : emptyValuePlaceHolder}
            </GridTableCell>
            <GridTableCell xs={0.5}>{item.position}</GridTableCell>
            <GridTableCell xs={0.5}>
                {itemAmountPaid
                    ? setFormatAmount(item.tp_mov, itemAmountPaid)
                    : emptyValuePlaceHolder}
            </GridTableCell>
            <GridTableCell xs={1}>
                {/**
                 * // TODO: This should be refactored in order to have real LineItem Statuses
                 * This scenario assumes that a LineItem can have a processed and success Bill validation,
                 * but the others (if there are) don't.
                 * This is a "temporary" solution in order to show the correct LineItem status in the UI.
                 * Find this tag in the Front and Back repos: LINE_ITEM_STATUS_TEMPORARY_SOLUTION
                 */}
                <StatusText status={item.frontStatus} />
            </GridTableCell>
            <GridTableCell xs={1}>{item.payment_details}</GridTableCell>
            <GridTableCell xs={1}>
                {hasProcessedStatus && item.bills[0]?.payment_date
                    ? DateTime.fromISO(item.bills[0]?.payment_date).toFormat('dd/MM/yyyy')
                    : emptyValuePlaceHolder}
            </GridTableCell>
            <GridTableCell xs={1}>{getTranslateMessage(item.message)}</GridTableCell>
            <Grid item xs={0.5} display="grid">
                <TableCellIcons sx={{backgroundColor: 'transparent'}}>
                    <Can
                        I={ConfirmDeliveryScheduleInvoiceEnum.REJECT_CONFIRM}
                        a={FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE}
                    >
                        {(item.frontStatus === OrderStatusEnum.CREATED ||
                            item.frontStatus === OrderStatusEnum.CONFIRMED) &&
                            item.material_type !== 'SERVICE' &&
                            Number(item.iterations) < 2 && (
                                <img
                                    style={{cursor: 'pointer'}}
                                    alt="Confirmación de PO"
                                    title={t('po_confirmation')}
                                    src={IconConfirmOrderAction}
                                    onClick={handleConfirmClick}
                                />
                            )}
                    </Can>
                    <Can
                        I={ConfirmDeliveryScheduleInvoiceEnum.VIEW_ENTRY_SHEET_DETAILS}
                        a={FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE}
                    >
                        {item.material_type === 'SERVICE' &&
                            item.type === 'FO' &&
                            item.bills.length > 0 && (
                                <Tooltip
                                    title={t('confirmposimply.bill_detail_modal.details_order')}
                                >
                                    <ArticleIcon
                                        style={{color: '00ACFF', cursor: 'pointer'}}
                                        onClick={() => showModalEntryDetail(item)}
                                    />
                                </Tooltip>
                            )}
                    </Can>
                </TableCellIcons>
            </Grid>
        </div>
    );
};

export default LineItemRow;
