export const statusTextValues: Record<string, string> = {
    CREATED: 'CREATED',
    REJECTED: 'REJECTED',
    CONFIRMED: 'CONFIRMED',
    DELIVERED: 'DELIVERED',
    BILL_VALIDATED: 'BILL_VALIDATED',
    INVOICE_IN_VALIDATION: 'INVOICE_IN_VALIDATION',
    INVOICE_IN_PROCESS: 'INVOICE_IN_PROCESS',
    REJECTED_INVOICE: 'REJECTED_INVOICE',
    SCHEDULED_TO_PAY: 'SCHEDULED_TO_PAY',
    PAID: 'PAID',
    CLOSED: 'CLOSED',
    GOODS_RETURN: 'GOODS_RETURN',
};

export const statusTextValuesAgents: Record<string, string> = {
    CREATED: 'CREATED',
    INVOICE_IN_PROCESS: 'INVOICE_IN_PROCESS',
    REJECTED_INVOICE: 'REJECTED_INVOICE',
    SCHEDULED_TO_PAY: 'SCHEDULED_TO_PAY',
    PAID: 'PAID',
};
//TODO: remove PARTIALLY_DELIVERED and PROCESS_PENDING BECAUSE THEY ARE NOT USED ANYMORE
/*
 PARTIALLY_DELIVERED: 'PARTIALLY_DELIVERED',
PROCESS_PENDING: 'PROCESS_PENDING',*/

export const modeloramaStatusTextValues: Record<string, string> = Object.entries(statusTextValues)
    .filter(([key]) => {
        const excludedStatuses = [
            'CONFIRMED',
            'CREATED',
            'REJECTED',
            'PARTIALLY_DELIVERED',
            'BILL_VALIDATED',
            'INVOICE_IN_VALIDATION',
        ];
        return !excludedStatuses.includes(key);
    })
    .reduce((acc: Record<string, string>, [key, value]) => {
        /*
         * Modelorama doesn't use the CONFIRMED status,
         * so we decided not to change this logic in the backend.
         * Instead, we changed the status here to match the desired behavior.
         * This ensures consistency between the frontend and backend.
         * // [ ] Tag: MODELORA-CONFIRMED-STATUS
         */
        acc[key] = key === 'CONFIRMED' ? 'CONFIRMED_MD' : value;
        return acc;
    }, {});

export const modeloramaStatusTextValuesReal: Record<string, string> = Object.entries(
    statusTextValues
)
    .filter(([key]) => {
        const excludedStatuses = [
            'CONFIRMED',
            'CREATED',
            'REJECTED',
            'PARTIALLY_DELIVERED',
            'BILL_VALIDATED',
            'INVOICE_IN_VALIDATION',
        ];
        return !excludedStatuses.includes(key);
    })
    .reduce((acc: Record<string, string>, [key, value]) => {
        /*
         * Modelorama doesn't use the CONFIRMED status,
         * so we decided not to change this logic in the backend.
         * Instead, we changed the status here to match the desired behavior.
         * This ensures consistency between the frontend and backend.
         * // [ ] Tag: MODELORA-CONFIRMED-STATUS
         */
        acc[key] = key === 'CREATED' ? 'CONFIRMED_MD' : value;
        return acc;
    }, {});

export const statusTextValuesInOrder: Record<string, string> = {
    INVOICE_IN_PROCESS: 'Factura en proceso',
    REJECTED_INVOICE: 'Factura rechazada',
    SCHEDULED_TO_PAY: 'Programado a pago',
};
