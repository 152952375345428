interface CfdiValidation {
    [key: string]: {
        name: string;
        description: string;
        showInList: boolean;
    };
}

export const getCfdiValidation = (key: string) => {
    return cfdiValidations[key] || cfdiValidations.default;
};

export const cfdiValidations: CfdiValidation = {
    'bill.currency-not-match-error': {
        name: 'Orden de compra',
        description: 'La moneda de la factura no coincide con la orden.',
        showInList: true,
    },
    'massive-cfdi-validation.purchase-order-invalid-status': {
        name: 'Orden de compra',
        description: 'El estado de la orden no es válido',
        showInList: true,
    },
    'massive-cfdi-validation.xml-file-not-found': {
        name: 'Orden de compra',
        description: 'No se encontró el archivo xml',
        showInList: true,
    },
    'massive-cfdi-validation.pdf-file-not-found': {
        name: 'Orden de compra',
        description: 'No se encontró el archivo pdf',
        showInList: true,
    },
    'massive-cfdi-validation.entry-sheet-not-match': {
        name: 'Orden de compra',
        description: 'La hoja de entrada no coincide',
        showInList: true,
    },
    'massive-cfdi-validation.purchase-order-not-found': {
        name: 'Orden de compra',
        description: 'No se encontró la orden de compra',
        showInList: true,
    },
    'massive-cfdi-validation.zip-missing-required-files': {
        name: 'Zip',
        description: 'El zip no tiene los archivos requeridos',
        showInList: true,
    },
    'massive-cfdi-validation.invalid-layout': {
        name: 'Zip',
        description: 'El archivo xls no tiene la estructura esperada (5 columnas)',
        showInList: true,
    },
    'core.error_extracting_zip': {
        name: 'Zip',
        description: 'Error en la extracción del Zip',
        showInList: true,
    },
    'cfdi.not-related': {
        name: 'Nota de credito',
        description: 'TipoRelacion no corresponde con el TipoComprobante',
        showInList: true,
    },
    'cfdi.type-receipt-not-match': {
        name: 'Nota de credito',
        description: 'El documento no trae UUID relacionado',
        showInList: true,
    },
    'cfdi.quantity-not-match': {
        name: 'Nota de credito',
        description: 'La cantidad no concuerda.',
        showInList: true,
    },
    'cfdi.credit-note-already-exists': {
        name: 'Nota de credito',
        description: 'Ya existe la nota de credito',
        showInList: true,
    },
    'cfdi.invoice-date-exceeds-thirty-days': {
        name: 'Fecha factura',
        description: 'Solo se permite facturas con fecha actual más 30 dias.',
        showInList: true,
    },
    'cfdi.invoice-current-year-tax-incorrect-no-modelorama': {
        name: 'Año fiscal',
        description: 'Año fiscal de factura NO en curso, verifique',
        showInList: true,
    },
    'cfdi.invoice-current-year-tax-incorrect': {
        name: 'Año fiscal',
        description:
            'Para poder procesar sus facturas de año fiscal NO en curso es necesario que adjunte sus archivos (PDF Y XML) al correo: Facturacion.Modeloramas@ab-inbev.com',
        showInList: true,
    },
    'cfdi-sub-total-has-a-difference-of-more-than-200': {
        name: 'Contrato Marco',
        description: 'Diferencia en importe de la factura vs monto de renta actual',
        showInList: true,
    },
    'cfdi.use-cfdi-is-incorrect': {
        name: 'Gastos en General',
        description: 'La clave incorrecta',
        showInList: true,
    },
    'cfdi.tax-with-holding-incorrect-tax-key': {
        name: 'Clave Impuesto Retención',
        description: 'La clave del impuesto no es correcto',
        showInList: true,
    },
    'cfdi.tax-holding-percentage-invalid': {
        name: 'Porcentaje Retención',
        description: 'Diferencia de retenciones entre CFDI y Sistema',
        showInList: true,
    },
    'cfdi.invoice-does-not-have-a-property-account': {
        name: 'Cuenta Predial',
        description: 'La factura no tiene Cuenta Predial, verifique la factura',
        showInList: true,
    },
    'cfdi.tax-transferred-incorrect-tax-key': {
        name: 'Clave Impuesto Trasladado',
        description: 'La clave del impuesto no es correcto',
        showInList: true,
    },
    'cfdi.code-product-service-invalid': {
        name: 'Cuenta predial',
        description: 'La factura no tiene Cuenta Predial, verifique la factura',
        showInList: true,
    },
    'cfdi.zip-code-error': {
        name: 'Domicilio Fiscal',
        description:
            'El Domicilio Fiscal RECEPTOR no es igual a su Constancia de Situación Fiscal, verifique',
        showInList: true,
    },
    'cfdi.tax-regimen-transmitter-is-incorrect': {
        name: 'Régimen Fiscal',
        description:
            'El Régimen Fiscal del EMISOR no es igual a su Constancia de Situación Fiscal, verifique',
        showInList: true,
    },
    'cfdi.tax-regimen-receiver-is-incorrect': {
        name: 'Régimen Fiscal',
        description:
            'El Régimen Fiscal del RECEPTOR no es igual a su Constancia de Situación Fiscal, verifique',
        showInList: true,
    },
    'cfdi.name-recevicer-they-are-different': {
        name: 'Razón Fiscal',
        description:
            'La Razón Fiscal del RECEPTOR no es igual a su Constancia de Situación Fiscal, verifique.',
        showInList: true,
    },
    'cfdi.name-transmitter-they-are-different': {
        name: 'Razón Fiscal',
        description:
            'La Razón Fiscal del EMISOR no es igual a su Constancia de Situación Fiscal, verifique.',
        showInList: true,
    },
    'sat.cfdi_status.request_failed': {
        name: 'Estado Fallido',
        description: 'Estado fallido',
        showInList: true,
    },
    'cfdi.payment-type-error': {
        name: 'Forma de Pago',
        description: 'Validar la Forma de Pago. Debe ser "99"',
        showInList: true,
    },
    'cfdi.payment-type-error-mk': {
        name: 'Forma de Pago',
        description: 'La forma de pago es invalida para esta orden',
        showInList: true,
    },
    'cfdi.payment-method-error': {
        name: 'Método de Pago',
        description: 'Validar el Método de Pago. Debe ser "PPD"',
        showInList: true,
    },
    'cfdi.payment-method-error-mk': {
        name: 'Método de Pago',
        description: 'El método de pago es invalido para esta orden',
        showInList: true,
    },
    'cfdi.validations-failed': {
        name: 'Validaciones',
        description: 'El CFDI tiene errores, favor de verificar.',
        showInList: true,
    },
    'cfdi.version-error': {
        name: 'Versión',
        description: 'La versión del CFDI debe ser "4.0"',
        showInList: true,
    },
    'cfdi.date-out-of-range': {
        name: 'Fecha',
        description: 'El CFDI no se encuentra dentro del mes en curso, favor de verificar',
        showInList: true,
    },
    'cfdi.exportation-error': {
        name: 'Exportación',
        description: 'La clave de Exportación de esta factura, no es válida, verifique.',
        showInList: true,
    },
    'cfdi.object-imp-error': {
        name: 'Object imp',
        description: 'Object imp error',
        showInList: true,
    },
    'cfdi.mismatch-relation-type-with-invoice-type': {
        name: 'Tipo de Relación',
        description: 'El TipoRelacion no corresponde con el TipoComprobante',
        showInList: true,
    },
    'csf.emitter.fiscalRegime.error': {
        name: 'Régimen Fiscal EMISOR',
        description:
            'El Régimen Fiscal del EMISOR no es igual a su Constancia de Situación Fiscal, verifique',
        showInList: true,
    },
    'csf.emitter.name.error': {
        name: 'Razón Fiscal EMISOR',
        description:
            'La Razón Fiscal del EMISOR no es igual a su Constancia de Situación Fiscal, verifique.',
        showInList: true,
    },
    'csf.emitter.rfc.error': {
        name: 'RFC EMISOR',
        description:
            'El RFC del EMISOR no es igual a su Constancia de Situación Fiscal, verifique.',
        showInList: true,
    },
    'csf.receiver.fiscalAddress.error': {
        name: 'Domicilio Fiscal RECEPTOR',
        description:
            'El Domicilio Fiscal RECEPTOR no es igual a su Constancia de Situación Fiscal, verifique',
        showInList: true,
    },
    'csf.receiver.fiscalRegime.error': {
        name: 'Régimen Fiscal RECEPTOR',
        description:
            'El Régimen Fiscal del RECEPTOR no es igual a su Constancia de Situación Fiscal, verifique',
        showInList: true,
    },
    'csf.receiver.name.error': {
        name: 'Razón Fiscal RECEPTOR',
        description:
            'La Razón Fiscal del RECEPTOR no es igual a su Constancia de Situación Fiscal, verifique.',
        showInList: true,
    },
    'csf.receiver.rfc.error': {
        name: 'RFC RECEPTOR',
        description:
            'El RFC del RECEPTOR no es igual a su Constancia de Situación Fiscal, verifique.',
        showInList: true,
    },
    'po.cfdi_amount.do_not_match_purchase_order': {
        name: 'Monto Factura',
        description:
            'El monto de la Factura (CFDI) no corresponde con el de la Orden de Compra y Entrada(s) de Mercancía',
        showInList: true,
    },
    'po.cfdi_amount.greater_than_purchase_order': {
        name: 'Importes Factura',
        description:
            'Los importes de la Factura (CFDI) no corresponde con el de la Orden de Compra y Entrada(s) de Mercancía',
        showInList: true,
    },
    'po.cfdi_quantities.do_not_match_purchase_order': {
        name: 'Cantidades Factura',
        description:
            'Las cantidades de la Factura (CFDI) no corresponde con el de la Orden de Compra y Entrada(s) de Mercancía',
        showInList: true,
    },
    'po.cfdi_payment_type.not_allowed': {
        name: 'Forma de Pago',
        description: 'La Forma de Pago diferente a "99" no está permitida',
        showInList: true,
    },
    'po.cfdi_currency.do_not_match_purchase_order': {
        name: 'Moneda',
        description: 'La moneda de la factura no coincide con la orden',
        showInList: true,
    },
    'tax.ieps.error': {
        name: 'IEPS',
        description: 'La validación de IEPS no pasó, favor de verificar.',
        showInList: true,
    },
    'tax.resico.error': {
        name: 'RESICO',
        description: 'La validación de RESICO no pasó, favor de verificar.',
        showInList: true,
    },
    'tax.four-percent-retention.error': {
        name: 'Retención del 4%',
        description: 'La validación de la retención del 4% no pasó, favor de verificar.',
        showInList: true,
    },
    'tax.eight-percent-iva-not-allowed.error': {
        name: 'Retención del 8%',
        description: 'La factura tiene un IVA distinto al permitido.',
        showInList: true,
    },
    'tax.bill.has-different-retentions.error': {
        name: 'Retenciones',
        description: 'La factura tiene distintas retenciones',
        showInList: true,
    },
    'tax.retention.iva.different-percentage.error': {
        name: 'Impuestos',
        description: 'La factura tiene distintos porcentajes de retención de IVA',
        showInList: true,
    },
    'tax.retention.isr.different-percentage.error': {
        name: 'Impuestos',
        description: 'La factura tiene distintos porcentajes de retención de ISR',
        showInList: true,
    },
    'tax.carried-iva-amount.not-match.error': {
        name: 'Impuestos',
        description:
            'El IVA trasladado no coincide con el con el de las hojas de entrada seleccionadas',
        showInList: true,
    },
    'tax.total-amount.not-match.error': {
        name: 'Subtotal Impuestos',
        description:
            'El monto del subtotal de los impuestos no coincide con el de las hojas de entrada seleccionadas',
        showInList: true,
    },
    'cfdi.status.cancelled': {
        name: 'CFDI',
        description: 'El CFDI está cancelado ante el SAT, favor de verificar',
        showInList: true,
    },
    'cfdi.status.not-found': {
        name: 'CFDI',
        description: 'El CFDI no fue encontrado en el SAT, favor de verificar',
        showInList: true,
    },
    'cfdi.uuid-already-exists': {
        name: 'CFDI',
        description: 'El UUID de esta factura ya existe en el sistema, favor de verificar',
        showInList: true,
    },
    'purchase_order.uploaded_cfdi.has_validation_errors': {
        name: 'CFDI',
        description: 'El CFDI tiene errores respecto a la Orden de Compra, favor de verificar',
        showInList: false,
    },
    'sap.confirm_error': {
        name: 'PROCESS_PENDING',
        description:
            'Hemos recibido tu información y estamos procesándola. Este proceso puede tardar unos minutos, te notificaremos cuando se haya completado.',
        showInList: true,
    },
    'massive-cfdi-validation.invalid-total': {
        name: 'Invalid Total',
        description: `confirmposimply.errors.massive_cfdi_validation.invalid_total`,

        showInList: true,
    },
    invalid_item_status: {
        name: 'Entrada con factura asociada',
        description: `confirmposimply.errors.massive_cfdi_validation.invalid_item_status`,
        showInList: true,
    },
    default: {
        name: 'Error',
        description: 'Error desconocido',
        showInList: false,
    },
};
