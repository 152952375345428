import SkeletonRow from '@/components/UI/molecules/tables/SkeletonRow';
import {
    TableCellHead,
    TableContainer,
    TableHeadRow,
} from '@/components/UI/molecules/tables/TablePrincipal';
import WarningToast from '@/components/auth/componenents/WarningToast';
import {ACLObj} from '@/components/auth/guard/AclGuard';
import {useModalMassiveJob} from '@/components/orderSimply/hooks/useModalMassiveJob';
import type {ITermsModal} from '@/components/orderSimply/interfaces/download-terms.interface';
import {IItemNew} from '@/components/orderSimply/interfaces/item-new.interface';
import Modal2WM from '@/components/orderSimply/modals/Modal2WM';
import Modal2WMPDF from '@/components/orderSimply/modals/Modal2WMPDF';
import ModalClientExternal from '@/components/orderSimply/modals/ModalClientExternal';
import ModalFileUpload from '@/components/orderSimply/modals/ModalFileUpload';
import ModalFileUploadMassive from '@/components/orderSimply/modals/ModalFileUploadMassive';
import ModalFileUploadNote from '@/components/orderSimply/modals/ModalFileUploadNote';
import ModalMassiveJob from '@/components/orderSimply/modals/ModalMassiveJob';
import {
    CHECK_TERMS_AND_DOWNLOAD,
    HIDE_TERMS_MODAL,
} from '@/components/orderSimply/redux/types/OrdersSimplyTypes';
import {CompanyTtypes, nationalModalAllowed} from '@/components/orderSimply/utils/constants';
import {assignBackgroundColor} from '@/components/orderSimply/utils/orderConfirmSimplyHelpers';
import TermsModal from '@/components/terms/modals/TermsModal';
import {UserType} from '@/components/user/interface';
import {Can} from '@/context/AbilityContext';
import {ConfirmDeliveryScheduleInvoiceEnum, FeatureCodeEnum} from '@/enums/permissions.enum';
import useCheckAbilities from '@/hooks/useCheckAbilities';
import ModalAlertCsf from '@/routers/ModalAlertCsfGo';
import {FF_ENABLE_TERMS_AND_CONDITIONS} from '@/utils/envvars';
import {statusTextValues} from '@components/UI/atoms/buttons/constant/status-text';
import CustomPagination from '@components/UI/organisms/pagination/Pagination';
import ErrorToast from '@components/auth/componenents/ErrorToast';
import LineItemRow from '@components/orderSimply/components/LineItemRow';
import {IFormDate} from '@components/orderSimply/interface';
import BillsDetailModal from '@components/orderSimply/modals/BillsDetailModal';
import DetailModal from '@components/orderSimply/modals/DetailModal';
import {UploadComplement} from '@components/orderSimply/modals/UploadComplement';
import {openDialog} from '@components/orderSimply/redux/actions/LoadComplementActions';
import {
    fetchDownloadOrder,
    fetchGetDownloadOrdersSimply,
} from '@components/orderSimply/redux/actions/OrderSimplyDownloadActions';
import {
    fetchGetOrdersConfirmSimply,
    getOrdersSimplyError,
    postOrderFilters,
} from '@components/orderSimply/redux/actions/OrdersSimplyActions';
import {AppDispatch, RootState} from '@config/store';
import {yupResolver} from '@hookform/resolvers/yup';
import {
    DescriptionOutlined,
    InsertDriveFileOutlined,
    Menu as MenuIcon,
    Newspaper,
    PaidOutlined,
    ReceiptLong,
    RequestPageOutlined,
    Search,
    VisibilityOutlined,
} from '@mui/icons-material';
import {
    Badge,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    OutlinedInput,
    Select,
    Tooltip,
    Typography,
    styled,
    useTheme,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {enUS, es} from 'date-fns/locale';
import {DateTime} from 'luxon';
import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import * as yup from 'yup';

const InputLabelRol = styled(InputLabel)({
    '&.Mui-focused': {
        color: '#011B34',
    },
    color: '#011B34',
    marginTop: '-5px',
});

export const StyledSelect = styled(Select)({
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '',
    },
    height: '40px',
    minWidth: '100px',
    width: 'auto',
    marginLeft: '10px',
});

export const processString = (inputString: string, t: any) => {
    const recordsKeys = inputString.split(',');
    const records = recordsKeys.map((key: string) => {
        return statusTextValues[key];
    });

    const firstRecord = records[0];
    const remainingRecordsCount = records.length - 1;
    let remainingRecordsMessage = '';
    if (remainingRecordsCount > 0) {
        remainingRecordsMessage = `(+${remainingRecordsCount})`;
    }
    return t(`modelorama.table.status.${firstRecord}`) + remainingRecordsMessage;
};

const OrderConfirmSimply: React.FC & {acl?: ACLObj} = () => {
    const navigate = useNavigate();
    const userLang = localStorage.getItem('user-lang') || 'es';
    const lang = userLang === 'es' ? es : enUS;
    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch: AppDispatch = useDispatch();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const searchOrderId = queryParams.get('order');
    const massiveJobId = queryParams.get('mjob');

    const {
        isModalMassiveJobOpen: isAfterMassiveModalOpen,
        handleCloseModalMassiveJob: handleCloseAfterMassiveModal,
    } = useModalMassiveJob({
        massiveJobId,
    });

    const {accessToken: token, user} = useSelector((state: RootState) => state.logins);
    const acceptedAndRejectedTermsCache = useSelector(
        (state: RootState) => state.orderssimply.acceptedAndRejectedTermsCache
    );
    const isSupplier = user.type === UserType.SUPPLIER;
    const websiteType = useSelector((state: RootState) => state.logins.user.company.websiteType);
    const userType = useSelector((state: RootState) => state.logins.user.type);
    const {orderssimply, termsModal}: {orderssimply: any; termsModal: ITermsModal} = useSelector(
        (state: RootState) => state.orderssimply
    );
    const currentPage = useSelector((state: RootState) => state.orderssimply.page);
    const limit = useSelector((state: RootState) => state.orderssimply.limit);
    const error = useSelector((state: RootState) => state.orderssimply.error);
    const totalPages = useSelector((state: RootState) => state.orderssimply.totalPages);
    const loading = useSelector((state: RootState) => state.orderssimply.loading);
    const uploadPDF2WM = useSelector((state: RootState) => state.complement.uploadPDF2WM);
    const filters = useSelector((state: RootState) => state.orderssimply.filters);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedStates, setSelectedStates] = useState<string[]>(Object.keys(statusTextValues));
    const [checkAllStatus, setCheckAllStatus] = useState(false);
    const [type, setType] = useState<string>('');
    const {notAllowed} = useCheckAbilities();
    const [modalPurchaseOrderId, setModalPurchaseOrderId] = useState<string>('');
    const [modalPurchaseOrderStatus, setModalPurchaseOrderStatus] = useState<string>('');
    const [poType, setPoType] = useState<string>('');
    const [isDetailModalOpen, setIsDetailModalOpen] = useState<boolean>(false);
    const [isDetailModalBillsOpen, setIsDetailModalBillsOpen] = useState<boolean>(false);
    const [modalLineItemsFilter, setModalLineItemsFilter] = useState<string[]>([]);
    const [lineItemsFilter, setLineItemsFilter] = useState<string[]>([]);
    const [lineItemsSelected, setLineItemsSelected] = useState<any[]>([]);
    const [selectedPurchaseOrderId, setSelectedPurchaseOrderId] = useState<string | null>(null);
    const [showMenuBadge, setShowMenuBadge] = useState<boolean>(false);
    const openCompDialog = useSelector((state: RootState) => state.complement.openDialog);
    const [isModalNoteOpen, setIsModalNoteOpen] = useState<boolean>(false);
    const [isModalExternalOpen, setIsModalForeignOpen] = useState<boolean>(false);
    const [isModalLocalNoteOpen, setIsModalLocalNoteOpen] = useState<boolean>(false);
    const [isModalMassiveOpen, setIsModalMassiveOpen] = useState<boolean>(false);
    const [isUploadReceipt, setIsUploadReceipt] = useState<boolean>(true);
    const [buttonLoadReceiptDisabled, setButtonLoadReceiptDisabled] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);
    const [selectedPurchaseOrderStatus, setSelectedPurchaseOrderStatus] = useState<string | null>(
        null
    );
    const [isModalOpenAlert, setIsModalOpenAlert] = useState(false);
    const csfAlert = useSelector((state: RootState) => state.logins.csfAlert);
    let previousColor: string, prevItemBackgroundColor: string;

    const isEnabledTermsAndConditions = FF_ENABLE_TERMS_AND_CONDITIONS === true;

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        dispatch(fetchGetOrdersConfirmSimply(token, value, limit, filters, selectedStates));
    };
    const handleChangeRowsPerPage = (event: any) => {
        dispatch(
            fetchGetOrdersConfirmSimply(token, 1, event.target.value, filters, selectedStates)
        );
    };
    const [currentItem, setCurrentItem] = useState(null);

    const [itemWithBills, setItemWithBills] = useState(null);

    const getFirstSelectItemWebsiteType = () => {
        const lastSelectOrders = orderssimply.filter(
            (lineItem: any) =>
                `${String(lineItem.line_item_id)}-${String(lineItem.entry_sheet_number)}` ===
                lineItemsFilter[0]
        );
        return lastSelectOrders && lastSelectOrders[0] ? lastSelectOrders[0].website_type : null;
    };

    const getCurrentSelectItemWebsiteType = (itemId: string) => {
        const lastSelectOrders = orderssimply.filter(
            (lineItem: any) =>
                `${String(lineItem.line_item_id)}-${String(lineItem.entry_sheet_number)}` === itemId
        );
        return lastSelectOrders && lastSelectOrders[0] ? lastSelectOrders[0].website_type : null;
    };

    const getFirstSelectItemOrderType = () => {
        const lastSelectOrders = orderssimply.filter(
            (lineItem: any) =>
                `${String(lineItem.line_item_id)}-${String(lineItem.entry_sheet_number)}` ===
                lineItemsFilter[0]
        );
        return lastSelectOrders && lastSelectOrders[0] ? lastSelectOrders[0] : null;
    };

    const getCurrentSelectItemOrderType = (itemId: string) => {
        const lastSelectOrders = orderssimply.filter(
            (lineItem: any) =>
                `${String(lineItem.line_item_id)}-${String(lineItem.entry_sheet_number)}` === itemId
        );
        return lastSelectOrders && lastSelectOrders[0] ? lastSelectOrders[0] : null;
    };

    const getAllItemsSameSheetNumber = (sheet: string) => {
        const lastSelectOrders = orderssimply.filter(
            (lineItem: any) => lineItem.entry_sheet_number === sheet
        );
        return lastSelectOrders;
    };

    const getFirstSelectItemStatus = () => {
        const lastSelectOrders = orderssimply.filter(
            (lineItem: any) =>
                `${String(lineItem.line_item_id)}-${String(lineItem.entry_sheet_number)}` ===
                lineItemsFilter[0]
        );
        return lastSelectOrders && lastSelectOrders[0] ? lastSelectOrders[0].frontStatus : null;
    };

    const getCurrentSelectItemStatus = (itemId: string) => {
        const lastSelectOrders = orderssimply.filter(
            (lineItem: any) =>
                `${String(lineItem.line_item_id)}-${String(lineItem.entry_sheet_number)}` === itemId
        );
        return lastSelectOrders && lastSelectOrders[0] ? lastSelectOrders[0].frontStatus : null;
    };

    const handleLineItemSelection = (
        event: React.ChangeEvent<HTMLInputElement>,
        lineItemFilter: string,
        orderId: string,
        orderStatus: string,
        society_id: string,
        orderType: string = ''
    ) => {
        const {checked} = event.target;

        let implement: string[] = [];
        let itemsSameSheetFull: any[] = [];

        if (checked) {
            setPoType(orderType);

            const firstSelectItemWebsiteType = getFirstSelectItemWebsiteType();
            const currentSelectItemWebsiteType = getCurrentSelectItemWebsiteType(lineItemFilter);

            if (
                firstSelectItemWebsiteType &&
                currentSelectItemWebsiteType &&
                firstSelectItemWebsiteType !== currentSelectItemWebsiteType
            ) {
                event.preventDefault();
                WarningToast({title: 'No se pueden mezclar entradas de nacionales y extranjeras'});
                return;
            }

            const firstSelectItemOrderType = getFirstSelectItemOrderType();
            const currentSelectItemOrderType = getCurrentSelectItemOrderType(lineItemFilter);

            if (
                firstSelectItemOrderType &&
                currentSelectItemOrderType &&
                firstSelectItemOrderType.type !== currentSelectItemOrderType.type &&
                firstSelectItemOrderType.society_id !== currentSelectItemOrderType.society_id &&
                firstSelectItemOrderType.vendor_id !== currentSelectItemOrderType.vendor_id
            ) {
                if (firstSelectItemOrderType.type !== 'FO') setButtonLoadReceiptDisabled(true);
                else setButtonLoadReceiptDisabled(false);
                event.preventDefault();
                WarningToast({
                    title: 'No se pueden mezclar entradas de diferente tipo, sociedad o proveedor',
                });
                return;
            }

            const firstSelectItemStatus = getFirstSelectItemStatus();

            const currentSelectItemStatus = getCurrentSelectItemStatus(lineItemFilter);

            if (
                firstSelectItemStatus &&
                currentSelectItemStatus &&
                firstSelectItemStatus !== currentSelectItemStatus
            ) {
                event.preventDefault();
                WarningToast({title: 'No se pueden mezclar entradas con diferentes estados.'});
                return;
            }

            if (currentSelectItemWebsiteType === 'FOREIGN') {
                const sheet = lineItemFilter.split('-')[1];
                itemsSameSheetFull = getAllItemsSameSheetNumber(sheet);
                if (itemsSameSheetFull.length > 0) {
                    implement = [
                        ...implement,
                        ...itemsSameSheetFull.map(
                            (imp: any) =>
                                `${String(imp.line_item_id)}-${String(imp.entry_sheet_number)}`
                        ),
                    ];
                }
            } else {
                implement = [lineItemFilter];
                const currentItem = orderssimply.find(
                    (lineItem: any) => lineItem.line_item_id === lineItemFilter.split('-')[0]
                );
                itemsSameSheetFull = [currentItem];
            }

            setLineItemsFilter((prevLineItems) => {
                const newLineItems = [...prevLineItems, ...implement];

                const uniqueSocieties = new Set(
                    newLineItems.map((id) => {
                        const order = orderssimply.find(
                            (item: any) =>
                                `${String(item.line_item_id)}-${String(
                                    item.entry_sheet_number
                                )}` === id
                        );
                        return order?.society_id;
                    })
                );

                setButtonLoadReceiptDisabled(uniqueSocieties.size > 1);
                return newLineItems;
            });

            setLineItemsSelected((prevLineItems) => {
                const newLineItems = [...prevLineItems, ...itemsSameSheetFull];
                return newLineItems;
            });

            if (currentSelectItemOrderType) {
                if (currentSelectItemOrderType.type !== 'FO') setButtonLoadReceiptDisabled(true);
                else setButtonLoadReceiptDisabled(false);
            }
            setShowMenuBadge(true);
            setSelectedPurchaseOrderId(orderId);
            setSelectedPurchaseOrderStatus(orderStatus);
        } else {
            setLineItemsFilter((prevLineItems) => {
                const updatedLineItems = prevLineItems.filter((item) => item !== lineItemFilter);

                const uniqueSocieties = new Set(
                    updatedLineItems.map((id) => {
                        const order = orderssimply.find(
                            (item: any) =>
                                `${String(item.line_item_id)}-${String(
                                    item.entry_sheet_number
                                )}` === id
                        );
                        return order?.society_id;
                    })
                );

                setButtonLoadReceiptDisabled(uniqueSocieties.size > 1);

                if (updatedLineItems.length === 0) {
                    setSelectedPurchaseOrderId(null);
                    setSelectedPurchaseOrderStatus(null);
                    setShowMenuBadge(false);
                }
                return updatedLineItems;
            });
        }
    };

    const schemaFormModal = yup.object({
        dateIni: yup.date().required(t('confirmposimply.required_start_date')),
        dateEnd: yup.date().required('Fecha final requerido'),
        status: yup.string(),
        year: yup.string(),
        societyName: yup.string(),
        purchaseOrder: yup.string(),
        society: yup.string(),
        supplierId: yup.string(),
        entrySheet: yup.string(),
    });
    const parseDate = (date: Date | string | DateTime): DateTime => {
        let dateTime: DateTime = DateTime.now().startOf('month');
        if (date && date instanceof DateTime) dateTime = date;

        if (date && date instanceof Date) dateTime = DateTime.fromJSDate(date);
        if (date && typeof date === 'string') dateTime = DateTime.fromISO(date);

        return dateTime;
    };
    const {handleSubmit, control, watch, setValue} = useForm<IFormDate>({
        resolver: yupResolver(schemaFormModal),
        defaultValues: {
            ...filters,
            purchaseOrder: searchOrderId ?? filters.purchaseOrder,
            dateIni: filters.dateIni
                ? new Date(filters.dateIni)
                : DateTime.now().startOf('month').toJSDate(),
            dateEnd: filters.dateEnd ? new Date(filters.dateEnd) : DateTime.now().toJSDate(),
        },
    });
    const watchForm = watch();

    const isDifferenceMoreThanOneYear = (watchForm: IFormDate) => {
        const dateEndStartOfDay = new Date(watchForm.dateEnd);
        dateEndStartOfDay.setHours(0, 0, 0, 0);

        const dateIniStartOfDay = new Date(watchForm.dateIni);
        dateIniStartOfDay.setHours(0, 0, 0, 0);

        const differenceInMs = Math.abs(dateEndStartOfDay.getTime() - dateIniStartOfDay.getTime());
        const oneYearInMs = 365 * 24 * 60 * 60 * 1000;
        return differenceInMs > oneYearInMs;
    };

    const onSubmit = () => {
        const defaultPage = 1;
        const hasExtraFilters =
            watchForm.supplierId ||
            watchForm.society ||
            watchForm.societyName ||
            watchForm.purchaseOrder ||
            watchForm.entrySheet;
        const requestPage = hasExtraFilters ? defaultPage : currentPage;

        const json = {
            dateIni: parseDate(watchForm.dateIni).toFormat('yyyy-MM-dd'),
            dateEnd: parseDate(watchForm.dateEnd).toFormat('yyyy-MM-dd'),
            status: watchForm.status ?? selectedStates.join(','),
            year: watchForm.year,
            society: watchForm.society,
            supplierId: watchForm.supplierId,
            societyName: watchForm.societyName,
            purchaseOrder: watchForm.purchaseOrder,
            entrySheet: watchForm.entrySheet,
        };

        if (isDifferenceMoreThanOneYear(watchForm)) {
            ErrorToast({title: t('range_year_exceed_year')});
            return;
        }
        if (!loading) {
            dispatch(postOrderFilters(json));
            dispatch(fetchGetOrdersConfirmSimply(token, requestPage, limit, json, selectedStates));
        }
    };

    const handleCloseAndReload = (event?: {preventDefault: () => void}, reason?: string) => {
        setShowMenuBadge(false);
        setLineItemsFilter([]);
        setLineItemsSelected([]);
        setCheckAllStatus(false);
        getOrdersSimplyError(false);
        handleCloseModal(event!, reason!);
    };

    const handleCloseAndReloadMassive = (event?: {preventDefault: () => void}, reason?: string) => {
        setLineItemsFilter([]);
        setLineItemsSelected([]);
        setCheckAllStatus(false);
        getOrdersSimplyError(false);
        handleCloseMassiveModal(event!, reason!);
    };

    const handleCloseAndReloadNote = (event?: {preventDefault: () => void}, reason?: string) => {
        setLineItemsFilter([]);
        setLineItemsSelected([]);
        setCheckAllStatus(false);
        getOrdersSimplyError(false);
        handleCloseNoteModal(event!, reason!);
    };

    const handleCloseAndReloadLocalNote = (
        event?: {preventDefault: () => void},
        reason?: string
    ) => {
        setLineItemsFilter([]);
        setLineItemsSelected([]);
        setCheckAllStatus(false);
        getOrdersSimplyError(false);
        handleCloseLocalNoteModal(event!, reason!);
    };
    const handleCloseAndReloadExternal = (
        event?: {preventDefault: () => void},
        reason?: string
    ) => {
        setLineItemsFilter([]);
        setLineItemsSelected([]);
        setCheckAllStatus(false);
        getOrdersSimplyError(false);
        handleCloseExternalModal(event!, reason!);
    };
    const handleChangeDownload = (format: any) => {
        const json = {
            dateIni: parseDate(watchForm.dateIni).toFormat('yyyy-MM-dd'),
            dateEnd: parseDate(watchForm.dateEnd).toFormat('yyyy-MM-dd'),
            status: watchForm.status ?? selectedStates.join(','),
            year: watchForm.year,
            society: watchForm.society,
            supplierId: watchForm.supplierId,
            societyName: watchForm.societyName,
            purchaseOrder: watchForm.purchaseOrder,
            entrySheet: watchForm.entrySheet,
        };
        dispatch(fetchGetDownloadOrdersSimply(token, format, json));
    };
    const handleChangeStatus = (event: any) => {
        const {
            target: {value},
        } = event;
        cleanFilterStatus(value);
    };

    const handleOpenModal = (
        type: string,
        purchaseOrderId = '',
        purchaseOrderStatus = '',
        lineItemsFilter: string[]
    ) => {
        const order = orderssimply.filter(
            (lineItem: any) => lineItem.purchase_order_id === purchaseOrderId
        );

        if (order.length > 0) {
            const websiteType = order[0].website_type;
            if (nationalModalAllowed.includes(websiteType)) {
                setModalOpen((prev) => !prev);
            } else {
                setIsModalForeignOpen((prev) => !prev);
            }
        }
        setType(type);
        setModalPurchaseOrderId(purchaseOrderId);
        setModalPurchaseOrderStatus(purchaseOrderStatus);
        setModalLineItemsFilter(lineItemsFilter);
    };

    const handleOpenModalNote = (
        type: string,
        purchaseOrderId = '',
        purchaseOrderStatus = '',
        lineItemsFilter: string[]
    ) => {
        setType(type);
        setModalPurchaseOrderId(purchaseOrderId);
        setModalPurchaseOrderStatus(purchaseOrderStatus);
        setModalLineItemsFilter(lineItemsFilter);
        setIsModalNoteOpen((prev) => !prev);
    };

    const handleOpenModalExternal = (
        type: string,
        purchaseOrderId = '',
        purchaseOrderStatus = '',
        lineItemsFilter: string[]
    ) => {
        setType(type);
        setModalPurchaseOrderId(purchaseOrderId);
        setModalPurchaseOrderStatus(purchaseOrderStatus);
        setModalLineItemsFilter(lineItemsFilter);
        setIsModalForeignOpen((prev) => !prev);
    };

    const handleOpenModalLocal = (
        type: string,
        purchaseOrderId = '',
        purchaseOrderStatus = '',
        lineItemsFilter: string[]
    ) => {
        setType(type);
        setModalPurchaseOrderId(purchaseOrderId);
        setModalPurchaseOrderStatus(purchaseOrderStatus);
        setModalLineItemsFilter(lineItemsFilter);
        setIsModalLocalNoteOpen((prev) => !prev);
    };
    const handleCloseModal = (event?: {preventDefault: () => void}, reason?: string) => {
        if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
            if (event) {
                event.preventDefault();
            }
        }
        setModalOpen(false);
    };

    const handleCloseMassiveModal = (event?: {preventDefault: () => void}, reason?: string) => {
        if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
            if (event) {
                event.preventDefault();
            }
        }
        setIsModalMassiveOpen(false);
    };

    const handleCloseNoteModal = (event?: {preventDefault: () => void}, reason?: string) => {
        if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
            if (event) {
                event.preventDefault();
            }
        }
        setIsModalNoteOpen(false);
    };

    const handleCloseExternalModal = (event?: {preventDefault: () => void}, reason?: string) => {
        if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
            if (event) {
                event.preventDefault();
            }
        }
        setIsModalForeignOpen(false);
    };

    const handleCloseLocalNoteModal = (event?: {preventDefault: () => void}, reason?: string) => {
        if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
            if (event) {
                event.preventDefault();
            }
        }
        setIsModalLocalNoteOpen(false);
    };

    const handleDownloadOrder = (item: IItemNew) => {
        if (isSupplier && isEnabledTermsAndConditions) {
            checkTermsForSupplier(item);
            return;
        }
        downloadFile(item);
    };

    const checkTermsForSupplier = (item: IItemNew) => {
        dispatch({type: CHECK_TERMS_AND_DOWNLOAD, payload: item});
    };

    const downloadFile = (item: IItemNew) => {
        dispatch(fetchDownloadOrder(item.purchase_order_id, token));
    };

    const hanldeCheckAllStatus = () => {
        setCheckAllStatus((prev) => {
            cleanFilterStatus(!prev ? Object.keys(statusTextValues) : []);
            return !prev;
        });
    };

    const setOpenCompDialog = () => {
        dispatch(openDialog());
    };

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        const order = orderssimply.filter(
            (lineItem: any) => lineItem.line_item_id === lineItemsFilter[0]
        );
        if (order.length > 0) {
            if (order[0].website_type === CompanyTtypes.FOREIGN) setIsUploadReceipt(false);
            else setIsUploadReceipt(true);
        }
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const cleanFilterStatus = (value: any) => {
        const selectedStatus =
            typeof value === 'string'
                ? value.split(',')
                : value.filter((item: string) => !item.includes('notCheck'));
        setSelectedStates(selectedStatus);
        let newArray = [];
        if (selectedStatus.length > 0) {
            newArray = selectedStatus.map((item: string) => {
                return Object.keys(statusTextValues).find((e: string) => e === item);
            });
        }
        setValue('status', newArray.length > 0 ? newArray.join(',') : '');
    };

    const showModalDetail = (item: any) => {
        setCurrentItem(item);
        setIsDetailModalOpen(true);
    };

    const hideTermsModal = (event?: {preventDefault: () => void}, reason?: string) => {
        if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
            if (event) {
                event.preventDefault();
            }
            return;
        }
        dispatch({type: HIDE_TERMS_MODAL});
    };

    useEffect(() => {
        if (token) {
            const status = watchForm.status ?? selectedStates.join(',');
            const json = {
                ...filters,
                purchaseOrder: searchOrderId ?? filters.purchaseOrder,
                dateIni: filters.dateIni ?? parseDate(watchForm.dateIni).toFormat('yyyy-MM-dd'),
                dateEnd: filters.dateEnd ?? parseDate(watchForm.dateEnd).toFormat('yyyy-MM-dd'),
                status,
                year: filters.year ?? watchForm.year ?? DateTime.now().year,
            };

            setValue('dateIni', json.dateIni);
            setValue('dateEnd', json.dateEnd);
            setSelectedStates(status.split(','));

            dispatch(postOrderFilters(json));
            dispatch(fetchGetOrdersConfirmSimply(token, currentPage, limit, json, selectedStates));
        }
    }, [token, searchOrderId]);

    useEffect(() => {
        setCheckAllStatus(selectedStates.length === Object.keys(statusTextValues).length);
    }, [selectedStates]);

    const showModalEntryDetail = (item: any) => {
        setItemWithBills(item);
        setIsDetailModalBillsOpen(true);
        setPoType(item.type);
    };

    const closeModalAlert = () => {
        setIsModalOpenAlert(false);
        navigate('/csf');
    };

    useEffect(() => {
        if (csfAlert) {
            setIsModalOpenAlert(true);
        }
    }, [csfAlert]);

    useEffect(() => {
        if (error && !['NONE', 'RULE'].includes(error)) {
            ErrorToast({title: t(`confirmposimply.errors.${error}`)});
        }
    }, [error]);

    const hasCreditNote = () => {
        const ordersf = orderssimply.filter((item: any) => {
            return lineItemsFilter.includes(
                `${String(item.line_item_id)}-${String(item.entry_sheet_number)}`
            );
        });
        return ordersf.some((item: any) => item.frontStatus === 'GOODS_RETURN');
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '15px 23px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '10px 0',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '24px',
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 400,
                    }}
                >
                    {t('confirmposimply.title')}
                </Typography>
            </Box>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Can
                    I={ConfirmDeliveryScheduleInvoiceEnum.FILTER}
                    a={FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '15px',
                            background: '#fff',
                            gap: '10px',
                        }}
                    >
                        <Can
                            I={ConfirmDeliveryScheduleInvoiceEnum.FIND_SUPPLIER}
                            a={FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE}
                        >
                            {userType === 'INTERNAL_USER' && (
                                <Controller
                                    name="supplierId"
                                    control={control}
                                    render={({field: {onChange, value}}) => {
                                        return (
                                            <TextField
                                                value={value}
                                                onChange={onChange}
                                                label={t('supplier')}
                                                size="small"
                                            />
                                        );
                                    }}
                                />
                            )}
                            <Controller
                                name="society"
                                control={control}
                                render={({field: {onChange, value}}) => {
                                    return (
                                        <TextField
                                            value={value}
                                            onChange={onChange}
                                            label={t('confirmposimply.filters.society')}
                                            size="small"
                                        />
                                    );
                                }}
                            />
                            <Controller
                                name="societyName"
                                control={control}
                                render={({field: {onChange, value = ''}}) => {
                                    return (
                                        <TextField
                                            value={value}
                                            onChange={onChange}
                                            label={t('confirmposimply.filters.society_name')}
                                            size="small"
                                        />
                                    );
                                }}
                            />
                        </Can>
                        <Can
                            I={ConfirmDeliveryScheduleInvoiceEnum.FIND_PO}
                            a={FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE}
                        >
                            <Controller
                                name="purchaseOrder"
                                control={control}
                                render={({field: {onChange, value = ''}}) => {
                                    return (
                                        <TextField
                                            value={value}
                                            onChange={onChange}
                                            label={t('consultpo.table.headers.purchase_order')}
                                            size="small"
                                        />
                                    );
                                }}
                            />
                        </Can>
                        <Controller
                            name="entrySheet"
                            control={control}
                            render={({field: {onChange, value}}) => {
                                return (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        label={t('receptpo.filters.entry_sheet')}
                                        size="small"
                                    />
                                );
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '15px',
                            background: '#fff',
                            gap: '10px',
                        }}
                    >
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                            <Controller
                                name="dateIni"
                                control={control}
                                defaultValue={DateTime.now().startOf('month').toJSDate()}
                                render={({field: {onChange, value}}) => (
                                    <DatePicker
                                        label={t('logbook_and_details.start_date')}
                                        value={parseDate(value)}
                                        onChange={onChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                            />
                                        )}
                                        minDate={
                                            new Date(
                                                new Date().setFullYear(new Date().getFullYear() - 1)
                                            )
                                        }
                                        maxDate={
                                            new Date(
                                                new Date(watchForm.dateEnd)
                                                    .toISOString()
                                                    .split('T')[0] + 'T23:59:59.999Z'
                                            )
                                        }
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                            <Controller
                                name="dateEnd"
                                control={control}
                                defaultValue={new Date()}
                                render={({field: {onChange, value}}) => (
                                    <DatePicker
                                        label={t('logbook_and_details.end_date')}
                                        value={parseDate(value)}
                                        onChange={onChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                }}
                                            />
                                        )}
                                        minDate={watchForm.dateIni}
                                        maxDate={
                                            new Date(
                                                new Date().toISOString().split('T')[0] +
                                                    'T23:59:59.999Z'
                                            )
                                        }
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        <Controller
                            name="year"
                            control={control}
                            defaultValue={new Date().getFullYear()}
                            render={({field, fieldState}) => (
                                <FormControl sx={{marginLeft: '10px', height: '40px'}}>
                                    <InputLabel>{t('components.year_selector.title')}</InputLabel>
                                    <Select
                                        label="Motivo de Rechazo"
                                        {...field}
                                        error={!!fieldState.error}
                                        sx={{width: '100px', height: '40px'}}
                                    >
                                        <MenuItem value={2025}>2025</MenuItem>
                                        <MenuItem value={2024}>2024</MenuItem>
                                        <MenuItem value={2023}>2023</MenuItem>
                                        <MenuItem value={2022}>2022</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        />
                        <Controller
                            render={({field, fieldState}) => (
                                <FormControl>
                                    <InputLabelRol>
                                        {t('components.status_selector.title')}{' '}
                                    </InputLabelRol>
                                    <StyledSelect
                                        {...field}
                                        multiple
                                        value={selectedStates}
                                        onChange={handleChangeStatus}
                                        input={<OutlinedInput label="Tag" />}
                                        renderValue={(selected: any) =>
                                            processString(selected.join(','), t)
                                        }
                                        MenuProps={{
                                            sx: {
                                                '&& .Mui-selected': {
                                                    backgroundColor: '#FFFF',
                                                },
                                                '&:hover': {
                                                    backgroundColor: 'transparent',
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem
                                            value={'notCheck'}
                                            onClick={() => {
                                                setTimeout(() => {
                                                    hanldeCheckAllStatus();
                                                }, 500);
                                            }}
                                        >
                                            <Checkbox
                                                checked={checkAllStatus}
                                                sx={{
                                                    '&.Mui-checked': {
                                                        color: theme.palette.primary.main,
                                                    },
                                                }}
                                            />
                                            <ListItemText
                                                primary={t(`consultpo.filters.select_all`)}
                                            />
                                        </MenuItem>
                                        {Object.keys(statusTextValues)
                                            .filter((name) => {
                                                if (userType === 'SUPPLIER') {
                                                    const arrayToFilter = [
                                                        'BILL_VALIDATED',
                                                        'CLOSED',
                                                    ];
                                                    return websiteType !== 'FOREIGN'
                                                        ? name !== 'INVOICE_IN_VALIDATION'
                                                        : !arrayToFilter.includes(name);
                                                }
                                                return true;
                                            })
                                            .map((key: string, index: number) => (
                                                <MenuItem key={index} value={key}>
                                                    <Checkbox
                                                        checked={selectedStates.indexOf(key) > -1}
                                                        sx={{
                                                            '&.Mui-checked': {
                                                                color: theme.palette.primary.main,
                                                            },
                                                        }}
                                                    />
                                                    <ListItemText
                                                        primary={t(
                                                            `modelorama.table.status.${statusTextValues[key]}`
                                                        )}
                                                    />
                                                </MenuItem>
                                            ))}
                                    </StyledSelect>
                                </FormControl>
                            )}
                            control={control}
                            name="status"
                        />
                        <Tooltip title={t('components.filter_btn.title')} placement="bottom" arrow>
                            <Button
                                type="submit"
                                aria-label="submit"
                                color="secondary"
                                variant="outlined"
                                sx={{
                                    minWidth: '40px',
                                    height: '36px',
                                    padding: '5px 5px',
                                    marginLeft: '10px',
                                }}
                                disabled={
                                    loading ||
                                    notAllowed(
                                        FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE,
                                        ConfirmDeliveryScheduleInvoiceEnum.FILTER
                                    )
                                }
                            >
                                <Search />
                            </Button>
                        </Tooltip>
                        <Box sx={{flex: '1 1 auto'}} />
                        <Tooltip title={t('options')} placement="bottom" arrow>
                            <Badge invisible={!showMenuBadge} color="primary" badgeContent="">
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleOpenMenu}
                                    id="menu-button"
                                    aria-controls={openMenu ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openMenu ? 'true' : undefined}
                                    sx={{
                                        minWidth: '40px',
                                        height: '36px',
                                        padding: '5px 5px',
                                        marginLeft: '10px',
                                    }}
                                >
                                    <MenuIcon />
                                </Button>
                            </Badge>
                        </Tooltip>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openMenu}
                            onClose={handleCloseMenu}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuList>
                                <MenuItem
                                    onClick={() => {
                                        window.location.href = '/confirmpo';
                                        handleCloseMenu();
                                    }}
                                >
                                    <ListItemIcon>
                                        <VisibilityOutlined fontSize="medium" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        {t('components.view_details_btn.title')}
                                    </ListItemText>
                                </MenuItem>
                                <Divider />
                                <MenuItem
                                    onClick={() => {
                                        setIsModalMassiveOpen((prev) => !prev);
                                        handleCloseMenu();
                                    }}
                                    disabled={notAllowed(
                                        FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE,
                                        ConfirmDeliveryScheduleInvoiceEnum.MASSIVE_LOADS
                                    )}
                                >
                                    <ListItemIcon>
                                        <RequestPageOutlined fontSize="medium" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        {t('components.massive_load_invoice.title')}
                                    </ListItemText>
                                </MenuItem>
                                {websiteType === CompanyTtypes.FOREIGN && (
                                    <MenuItem
                                        onClick={() => {
                                            handleOpenModalExternal(
                                                '',
                                                selectedPurchaseOrderId!,
                                                selectedPurchaseOrderStatus!,
                                                lineItemsFilter
                                            );
                                            handleCloseMenu();
                                        }}
                                        disabled={
                                            lineItemsFilter.length === 0 ||
                                            notAllowed(
                                                FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE,
                                                ConfirmDeliveryScheduleInvoiceEnum.UPLOAD_BILLS
                                            )
                                        }
                                    >
                                        <ListItemIcon>
                                            <RequestPageOutlined fontSize="medium" />
                                        </ListItemIcon>
                                        <ListItemText>
                                            {t('components.load_invoice_btn.title')}
                                        </ListItemText>
                                    </MenuItem>
                                )}
                                {websiteType === CompanyTtypes.NATIONAL && (
                                    <>
                                        <MenuItem
                                            onClick={() => {
                                                setOpenCompDialog();
                                                handleCloseMenu();
                                            }}
                                            disabled={notAllowed(
                                                FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE,
                                                ConfirmDeliveryScheduleInvoiceEnum.LOAD_PAYMENT_ADD_ON
                                            )}
                                        >
                                            <ListItemIcon>
                                                <PaidOutlined fontSize="medium" />
                                            </ListItemIcon>
                                            <ListItemText>
                                                {t('uploadComplement.load_complement')}
                                            </ListItemText>
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                handleOpenModalLocal(
                                                    '',
                                                    selectedPurchaseOrderId!,
                                                    selectedPurchaseOrderStatus!,
                                                    lineItemsFilter
                                                );
                                                handleCloseMenu();
                                            }}
                                            disabled={
                                                lineItemsFilter.length === 0 ||
                                                !hasCreditNote() ||
                                                notAllowed(
                                                    FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE,
                                                    ConfirmDeliveryScheduleInvoiceEnum.UPLOAD_CREDIT_NOTE
                                                )
                                            }
                                        >
                                            <ListItemIcon>
                                                <ReceiptLong fontSize="medium" />
                                            </ListItemIcon>
                                            <ListItemText>
                                                {t('components.load_credit_note.title')}
                                            </ListItemText>
                                        </MenuItem>
                                        {isUploadReceipt && (
                                            <MenuItem
                                                onClick={() => {
                                                    handleOpenModalNote(
                                                        '',
                                                        selectedPurchaseOrderId!,
                                                        selectedPurchaseOrderStatus!,
                                                        lineItemsFilter
                                                    );
                                                    handleCloseMenu();
                                                }}
                                                disabled={
                                                    lineItemsFilter.length === 0 ||
                                                    notAllowed(
                                                        FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE,
                                                        ConfirmDeliveryScheduleInvoiceEnum.UPLOAD_RECEIPT
                                                    ) ||
                                                    buttonLoadReceiptDisabled
                                                }
                                            >
                                                <ListItemIcon>
                                                    <Newspaper fontSize="medium" />
                                                </ListItemIcon>
                                                <ListItemText>
                                                    {t('components.load_receipt_btn.title')}
                                                </ListItemText>
                                            </MenuItem>
                                        )}
                                    </>
                                )}
                                <Can
                                    I={ConfirmDeliveryScheduleInvoiceEnum.EXPORT_EXCEL_CSV}
                                    a={FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE}
                                >
                                    <Divider />
                                    <MenuItem
                                        onClick={() => {
                                            handleChangeDownload('csv');
                                            handleCloseMenu();
                                        }}
                                        disabled={notAllowed(
                                            FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE,
                                            ConfirmDeliveryScheduleInvoiceEnum.EXPORT_EXCEL_CSV
                                        )}
                                    >
                                        <ListItemIcon>
                                            <DescriptionOutlined fontSize="medium" />
                                        </ListItemIcon>
                                        <ListItemText>
                                            {t('components.download_csv_btm.title')}
                                        </ListItemText>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            handleChangeDownload('xlsx');
                                            handleCloseMenu();
                                        }}
                                        disabled={notAllowed(
                                            FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE,
                                            ConfirmDeliveryScheduleInvoiceEnum.EXPORT_EXCEL_CSV
                                        )}
                                    >
                                        <ListItemIcon>
                                            <InsertDriveFileOutlined fontSize="medium" />
                                        </ListItemIcon>
                                        <ListItemText>
                                            {t('components.download_xlsx_btm.title')}
                                        </ListItemText>
                                    </MenuItem>
                                </Can>
                            </MenuList>
                        </Menu>
                    </Box>
                </Can>
            </Box>
            <TableContainer container>
                <TableHeadRow container>
                    {/* Primera fila */}
                    <Grid item xs={1}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('consultpo.table.headers.purchase_order')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={0.5}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('confirmposimply.filters.society')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('confirmposimply.filters.society_name')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('confirmposimply.table.headers.invoice_reference')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('confirmposimply.table.headers.material_text_description')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={0.5}>
                        <TableCellHead sx={{fontSize: '9.5px'}}> {t('unit_price')}</TableCellHead>
                    </Grid>
                    <Grid item xs={0.5}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>{t('money')}</TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('confirmposimply.table.headers.goods_receipt_reference')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('confirmposimply.table.headers.entry_sheet_number')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('confirmposimply.table.headers.invoice_date')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={0.5}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('confirmposimply.entry_sheet_modal.position')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={0.5}>
                        <TableCellHead sx={{fontSize: '9.5px'}}> {t('net_amount')}</TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('components.status_selector.title')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('components.payment_details.title')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('consultpo.table.headers.pay_day')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={1}>
                        <TableCellHead sx={{fontSize: '9.5px'}}>
                            {t('confirmposimply.messages')}
                        </TableCellHead>
                    </Grid>
                    <Grid item xs={0.5}>
                        <TableCellHead sx={{fontSize: '9.5px'}}> {t('actions')}</TableCellHead>
                    </Grid>
                </TableHeadRow>
                {loading ? (
                    <>
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                    </>
                ) : (
                    orderssimply
                        .filter(
                            (item: any) =>
                                !acceptedAndRejectedTermsCache.includes(item.purchase_order_id)
                        )
                        .map((item: any, index: number) => {
                            let backgroundColor;
                            ({backgroundColor, previousColor, prevItemBackgroundColor} =
                                assignBackgroundColor(
                                    orderssimply[index - 1]
                                        ? orderssimply[index - 1].purchase_order_id
                                        : null,
                                    item.purchase_order_id,
                                    previousColor,
                                    prevItemBackgroundColor
                                ));
                            return (
                                <LineItemRow
                                    key={index}
                                    item={item}
                                    backgroundColor={backgroundColor}
                                    selectedLineItems={lineItemsFilter}
                                    handleLineItemSelection={handleLineItemSelection}
                                    handleDownloadOrder={() => handleDownloadOrder(item)}
                                    showModalDetail={showModalDetail}
                                    showModalEntryDetail={showModalEntryDetail}
                                    poType={item.type}
                                />
                            );
                        })
                )}
            </TableContainer>
            <Box display={'flex'} padding={'20px 0'} justifyContent={'space-between'}>
                <div />
                <CustomPagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    handleChange={handleChange}
                    limit={limit}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <Can
                    I={ConfirmDeliveryScheduleInvoiceEnum.UPLOAD_BILLS}
                    a={FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE}
                >
                    <Button
                        variant="contained"
                        sx={{
                            height: '36px',
                            padding: '5px 5px',
                            color: '#FFFFFF',
                        }}
                        onClick={() => {
                            handleOpenModal(
                                'Factura',
                                selectedPurchaseOrderId!,
                                selectedPurchaseOrderStatus!,
                                lineItemsFilter
                            );
                        }}
                        disabled={lineItemsFilter.length === 0 || hasCreditNote()}
                    >
                        {t('components.load_invoice_btn.title')}
                    </Button>
                </Can>
            </Box>

            <ModalFileUploadMassive
                closeAndReload={handleCloseAndReloadMassive}
                closeModal={handleCloseMassiveModal}
                isModalOpen={isModalMassiveOpen}
                loading={loading}
                type={type}
            />
            {isAfterMassiveModalOpen && (
                <ModalMassiveJob
                    closeModal={handleCloseAfterMassiveModal}
                    isModalOpen={isAfterMassiveModalOpen}
                    loading={loading}
                />
            )}
            <ModalFileUpload
                closeAndReload={handleCloseAndReload}
                closeModal={handleCloseModal}
                lineItemsFilter={modalLineItemsFilter}
                isModalOpen={isModalOpen && poType !== 'FO'}
                loading={loading}
                purchaseOrderId={modalPurchaseOrderId}
                purchaseOrderStatus={modalPurchaseOrderStatus}
                type={type}
            />
            <Modal2WM
                closeAndReload={handleCloseAndReload}
                closeModal={handleCloseModal}
                lineItemsFilter={modalLineItemsFilter}
                lineItems={lineItemsSelected}
                isModalOpen={isModalOpen && poType === 'FO'}
                loading={loading}
                purchaseOrderId={modalPurchaseOrderId}
                purchaseOrderStatus={modalPurchaseOrderStatus}
                type={type}
            />
            <Modal2WMPDF
                closeAndReload={handleCloseAndReloadNote}
                closeModal={handleCloseNoteModal}
                lineItemsFilter={modalLineItemsFilter}
                isModalOpen={isModalNoteOpen}
                loading={uploadPDF2WM}
                purchaseOrderId={modalPurchaseOrderId}
                purchaseOrderStatus={modalPurchaseOrderStatus}
                type={type}
            />
            <ModalFileUploadNote
                closeAndReload={handleCloseAndReloadLocalNote}
                closeModal={handleCloseLocalNoteModal}
                lineItemsFilter={modalLineItemsFilter}
                isModalOpen={isModalLocalNoteOpen}
                loading={loading}
                type={type}
            />
            <ModalClientExternal
                closeAndReload={handleCloseAndReloadExternal}
                closeModal={handleCloseExternalModal}
                lineItemsFilter={modalLineItemsFilter}
                isModalOpen={isModalExternalOpen}
                loading={loading}
                purchaseOrderId={modalPurchaseOrderId}
                purchaseOrderStatus={modalPurchaseOrderStatus}
                type={type}
            />
            {isDetailModalOpen && currentItem && (
                <DetailModal
                    isModalOpen={isDetailModalOpen}
                    closeModal={() => setIsDetailModalOpen(false)}
                    item={currentItem}
                />
            )}
            {isDetailModalBillsOpen && itemWithBills && (
                <BillsDetailModal
                    isModalOpen={isDetailModalBillsOpen}
                    closeModal={() => setIsDetailModalBillsOpen(false)}
                    item={itemWithBills}
                    poType={poType}
                />
            )}
            <UploadComplement open={openCompDialog} />
            <ModalAlertCsf isModalOpen={isModalOpenAlert} closeModal={closeModalAlert} />
            {termsModal?.selectedItem && (
                <TermsModal
                    isOpen={termsModal?.showTermsModal}
                    onClose={() => hideTermsModal()}
                    termsData={{
                        externalIds: [termsModal?.selectedItem?.external_id],
                        purchaseOrderIds: [termsModal?.selectedItem?.purchase_order_id],
                    }}
                    onFulfill={() => {
                        fetchDownloadOrder(
                            termsModal?.selectedItem?.purchase_order_id,
                            token
                        )(dispatch);
                    }}
                    afterAction={onSubmit}
                />
            )}
        </Box>
    );
};

OrderConfirmSimply.acl = {
    action: ConfirmDeliveryScheduleInvoiceEnum.VIEW_LIST,
    subject: FeatureCodeEnum.CONFIRM_DELIVERY_SCHEDULE_INVOICE,
};

export default OrderConfirmSimply;
