export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const GET_NOTIFICATION_ERROR = 'GET_NOTIFICATION_ERROR';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';

export const POST_NOTIFICATION = 'POST_NOTIFICATION';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const POST_NOTIFICATION_ERROR = 'POST_NOTIFICATION_ERROR';
export const POST_NOTIFICATION_SUCCESS = 'POST_NOTIFICATION_SUCCESS';

export interface NotificationItemType {
    id: string;
    notification: INotificationPush;
    enterpriseId?: string;
    userType: string;
    viewUsers?: string[];
}
export interface INotificationPush {
    id: string;
    title: string;
    date: string;
    description: string;
    isRead: boolean;
}

export interface NotificationReduceType {
    notifications: NotificationItemType[];
    loading: boolean;
    error: boolean;
}
