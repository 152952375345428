/* eslint-disable no-undef */
import {
    GET_EXPENSE_ACCOUNT,
    GET_EXPENSE_ACCOUNT_ERROR,
    GET_EXPENSE_ACCOUNT_SUCCESS,
} from '@/components/orderSimply/redux/types/OrderAgentsTypes';
import {
    ACCEPTED_AND_REJECTED_TERMS_CACHE,
    CLEAN_ACCEPTED_AND_REJECTED_TERMS_CACHE,
    CREATE_FILE_XML_3WM,
    CREATE_FILE_XML_3WM_ERROR,
    CREATE_FILE_XML_3WM_SUCCESS,
    CREATE_FILE_XML_NOTE,
    CREATE_FILE_XML_NOTE_ERROR,
    CREATE_FILE_XML_NOTE_SUCCESS,
    GET_DOWNLOAD_ORDERSSIMPLY,
    GET_DOWNLOAD_ORDERSSIMPLY_ERROR,
    GET_DOWNLOAD_ORDERSSIMPLY_SUCCESS,
    GET_MASSIVE_JOB,
    GET_MASSIVE_JOB_ERROR,
    GET_MASSIVE_JOB_ERROR_DOMAIN,
    GET_MASSIVE_JOB_SUCCESS,
    GET_ORDERSSIMPLY,
    GET_ORDERSSIMPLY_CONFIRM,
    GET_ORDERSSIMPLY_CONFIRM_ERROR,
    GET_ORDERSSIMPLY_CONFIRM_SIMPLY,
    GET_ORDERSSIMPLY_CONFIRM_SIMPLY_ERROR,
    GET_ORDERSSIMPLY_CONFIRM_SIMPLY_SUCCESS,
    GET_ORDERSSIMPLY_CONFIRM_SUCCESS,
    GET_ORDERSSIMPLY_ERROR,
    GET_ORDERSSIMPLY_SUCCESS,
    GET_ORDERS_AGENTS,
    GET_ORDERS_AGENTS_ERROR,
    GET_ORDERS_AGENTS_SUCCESS,
    GET_ORDERS_ERRORS,
    GET_ORDERS_ERRORS_ERROR,
    GET_ORDERS_ERRORS_SUCCESS,
    GET_ORDERS_FILTERS,
    GET_ORDER_ENTRY_SHEET,
    GET_ORDER_ENTRY_SHEET_ERROR,
    GET_ORDER_ENTRY_SHEET_SUCCESS,
    POST_PARSE_XML,
    POST_PARSE_XML_ERROR,
    POST_PARSE_XML_SUCCESS,
    POST_XML_3WM,
    POST_XML_3WM_ERROR,
    POST_XML_3WM_SUCCESS,
    POST_ZIP_MASSIVE,
    POST_ZIP_MASSIVE_ERROR,
    POST_ZIP_MASSIVE_ERROR_DOMAIN,
    POST_ZIP_MASSIVE_SUCCESS,
    SEND_ACCEPT_TERMS,
    SEND_ACCEPT_TERMS_ERROR,
    SEND_ACCEPT_TERMS_SUCCESS,
    SET_MASSIVE_SUCCESS,
} from '@/components/orderSimply/redux/types/OrdersSimplyTypes';
import {ErrorType} from '@/components/orderSimply/utils/format-orders';
import ErrorToast from '@components/auth/componenents/ErrorToast';
import StringUtils from '@utils/StringUtils';
import axios from 'axios';

const switchErrors = {
    'cfdi.payment-method-error': 'cfdi.payment-method-error-mk',
    'cfdi.payment-type-error': 'cfdi.payment-type-error-mk',
};
export const postOrderFilters = (result) => {
    return {
        type: GET_ORDERS_FILTERS,
        value: result,
    };
};
export const getOrdersSimply = () => {
    return {
        type: GET_ORDERSSIMPLY,
    };
};
export const getOrdersSimplySuccess = (result, metadata) => {
    return {
        type: GET_ORDERSSIMPLY_SUCCESS,
        value: result,
        metadata: metadata,
    };
};
export const getOrdersSimplyError = (error) => {
    return {
        type: GET_ORDERSSIMPLY_ERROR,
        value: error,
    };
};

export function fetchGetOrdersSimply(token, page, limit, filters = {}, states) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        let params = null;
        if (Object.keys(filters).length === 0) {
            params = {
                page,
                limit,
            };
        } else {
            params = {
                page,
                limit,
                initDate: filters.dateIni,
                endDate: filters.dateEnd,
                year: filters.year,
                status: filters.status,
                modelorama: filters.modelorama,
                referenceId: filters.referenceId,
                supplierId: filters.supplierId,
            };
            if (filters.contractBusinessNumber) {
                params.contractBusinessNumber = filters.contractBusinessNumber;
            }
        }
        const queryString = StringUtils.jsonToQueryString(params);
        dispatch(getOrdersSimply());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/purchase-order/list/all-new${queryString}`,
                {headers}
            );
            const items = response.data.data;

            dispatch(getOrdersSimplySuccess(items, response.data));
        } catch (error) {
            dispatch(getOrdersSimplyError(true));
        }
    };
}

export const getOrdersConfirm = () => {
    return {
        type: GET_ORDERSSIMPLY_CONFIRM,
    };
};
export const getOrdersConfirmSuccess = (result, metadata) => {
    return {
        type: GET_ORDERSSIMPLY_CONFIRM_SUCCESS,
        value: result,
        metadata: metadata,
    };
};
export const getOrdersConfirmError = (error) => {
    return {
        type: GET_ORDERSSIMPLY_CONFIRM_ERROR,
        value: error,
    };
};

export function fetchGetOrdersConfirm(token, page, limit, filters = {}, states) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        let params = null;
        if (Object.keys(filters).length === 0) {
            params = {
                page,
                limit,
            };
        } else {
            params = {
                page,
                limit,
                initDate: filters.dateIni,
                endDate: filters.dateEnd,
                status: filters.status,
                year: filters.year,
                societyId: filters.society ?? '',
                societyName: filters.societyName ?? '',
                referenceId: filters.purchaseOrder ?? '',
                entrySheet: filters.entrySheet ?? '',
            };
        }
        const queryString = StringUtils.jsonToQueryString(params);
        dispatch(getOrdersConfirm());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/purchase-order/list/all-new${queryString}`,
                {headers}
            );

            const items = response.data.data;
            const newArray = items.flat().filter((order) => states.includes(order.frontStatus));
            dispatch(cleanAcceptedAndRejectedTermsCache());
            dispatch(getOrdersConfirmSuccess(newArray, response.data));
        } catch (error) {
            dispatch(getOrdersConfirmError(true));
        }
    };
}

export const getOrdersConfirmSimply = () => {
    return {
        type: GET_ORDERSSIMPLY_CONFIRM_SIMPLY,
    };
};
export const getOrdersConfirmSimplySuccess = (result, metadata) => {
    return {
        type: GET_ORDERSSIMPLY_CONFIRM_SIMPLY_SUCCESS,
        value: result,
        metadata: metadata,
    };
};
export const getOrdersConfirmSimplyError = (error) => {
    return {
        type: GET_ORDERSSIMPLY_CONFIRM_SIMPLY_ERROR,
        value: error,
    };
};
let cancelFilter = null;
export const fetchGetOrdersConfirmSimply = (token, page, limit, filters = {}, states) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        let params = null;
        if (Object.keys(filters).length === 0) {
            params = {
                page,
                limit,
            };
        } else {
            params = {
                page,
                limit,
                initDate: filters.dateIni,
                endDate: filters.dateEnd,
                status: filters.status,
                year: filters.year,
                societyId: filters.society ?? '',
                societyName: filters.societyName ?? '',
                referenceId: filters.purchaseOrder ?? '',
                supplierId: filters.supplierId ?? '',
                entrySheet: filters.entrySheet ?? '',
            };
        }
        const queryString = StringUtils.jsonToQueryString(params);

        try {
            if (cancelFilter) {
                cancelFilter.cancel('Request canceled due to a new request.');
            }
            cancelFilter = axios.CancelToken.source();
            dispatch(getOrdersConfirmSimply());
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/purchase-order/list/all-new${queryString}`,
                {headers, cancelToken: cancelFilter.token}
            );
            const items = response.data.data;
            const result = items.map((item) => ({
                ...item,
                bills: item.bills.filter((bill) => Object.keys(bill).length > 0),
            }));

            await dispatch(getOrdersConfirmSimplySuccess(result, response.data));

            const receivedLineItemIds = response.data.data
                .filter((order) => order.bills.length > 0)
                .map((order) => order.line_item_id);

            if (receivedLineItemIds.length > 0) {
                await dispatch(fetchGetOrdersErrors(token, receivedLineItemIds));
            } else {
                await dispatch(getOrdersErrorsSuccess([], {}));
            }
        } catch (error) {
            /* eslint-disable */
            if (axios.isCancel(error)) {
                console.log('Previous request canceled:', error.message);
            } else {
                console.log(error);
                console.log(error.response.data.message);
                dispatch(getOrdersConfirmSimplyError(error.response.data.error));
                /* eslint-enable */
            }
        }
    };
};

export const getOrdersErrors = () => {
    return {
        type: GET_ORDERS_ERRORS,
    };
};
export const getOrdersErrorsSuccess = (result, metadata) => {
    return {
        type: GET_ORDERS_ERRORS_SUCCESS,
        value: result,
        metadata: metadata,
    };
};
export const getOrdersErrorsError = (error) => {
    return {
        type: GET_ORDERS_ERRORS_ERROR,
        value: error,
    };
};

export const fetchGetOrdersErrors = (token, lineItemIds) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch) => {
        try {
            if (lineItemIds.length > 0) {
                dispatch(getOrdersErrors());
                const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/v2/purchase-order/list/all-errors`,
                    {lineItemIds},
                    {headers}
                );
                const errors = response.data.data;

                dispatch(getOrdersErrorsSuccess(errors, response.data));
            } else {
                dispatch(getOrdersErrorsSuccess([], {}));
            }
        } catch (error) {
            /* eslint-disable */
            if (axios.isCancel(error)) {
                console.log('Previous request canceled:', error.message);
            } else {
                console.log(error);
                dispatch(getOrdersErrorsError(true));
                /* eslint-enable */
            }
        }
    };
};

export const getOrdersSimplyDownload = () => {
    return {
        type: GET_DOWNLOAD_ORDERSSIMPLY,
    };
};
export const getOrdersSimplyDownloadSuccess = (result) => {
    return {
        type: GET_DOWNLOAD_ORDERSSIMPLY_SUCCESS,
        value: result,
    };
};
export const getOrdersSimplyDownloadError = (error) => {
    return {
        type: GET_DOWNLOAD_ORDERSSIMPLY_ERROR,
        value: error,
    };
};

export function fetchGetOrdersSimplyDownload(token, format, filters = {}) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        let params = null;
        if (Object.keys(filters).length === 0) {
            params = {};
        } else {
            params = {
                dateIni: filters.dateIni,
                dateEnd: filters.dateEnd,
            };
        }
        const queryString = StringUtils.jsonToQueryString(params);
        dispatch(getOrdersSimplyDownload());
        try {
            const response = await axios({
                url: `${process.env.REACT_APP_BASE_URL}/v2/binnacles/download/${format}${queryString}`,
                method: 'GET',
                headers: headers,
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));

            const a = document.createElement('a');
            a.href = url;
            a.download = `binnacles.${format}`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            dispatch(getOrdersSimplyDownloadSuccess());
        } catch (error) {
            dispatch(getOrdersSimplyDownloadError(true));
        }
    };
}
export const getOrdersAgents = () => {
    return {
        type: GET_ORDERS_AGENTS,
    };
};

export const getOrdersAgentsSuccess = (result, metadata) => {
    return {
        type: GET_ORDERS_AGENTS_SUCCESS,
        value: result,
        metadata: metadata,
    };
};
export const getOrdersAgentsError = (error) => {
    return {
        type: GET_ORDERS_AGENTS_ERROR,
        value: error,
    };
};

export const postFileAbi = () => {
    return {
        type: POST_XML_3WM,
    };
};

export const postFileAbiSuccess = (result) => {
    return {
        type: POST_XML_3WM_SUCCESS,
        value: result,
    };
};

export const postFileAbiError = (error, result) => {
    return {
        type: POST_XML_3WM_ERROR,
        value: error,
        payload: result,
    };
};

export const postParseXmlFile = () => {
    return {
        type: POST_PARSE_XML,
    };
};

export const postParseXMLFileSuccess = (result) => {
    return {
        type: POST_PARSE_XML_SUCCESS,
        value: result,
    };
};

export const postParseXmlFileError = (error, result) => {
    return {
        type: POST_PARSE_XML_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPostParseFileXml(token, data) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const formData = new FormData();
    formData.append('file', data.xml);

    return async (dispatch) => {
        dispatch(postXmlFile3wm());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/v2/utils/xml-parser/cfdi`,
                formData,
                {headers}
            );
            if (response) {
                dispatch(postParseXMLFileSuccess({...response.data?.data, error: ErrorType.NONE}));
            }
        } catch (error) {
            if (['ERR_CONNECTION_REFUSED', 'ERR_NETWORK'].includes(error.code)) {
                dispatch(
                    postParseXMLFileSuccess({error: ErrorType.NETWORK, errors: ['network.error']})
                );
            }

            if (error.response?.data?.error === 'auth.unauthorized') {
                dispatch(
                    postParseXmlFileError({error: ErrorType.AUTH, errors: ['auth.unauthorized']})
                );
            }

            const ruleValidationData = error?.response?.data?.data;
            if (ruleValidationData) {
                dispatch(
                    postParseXMLFileSuccess({
                        errors: ruleValidationData.errors,
                        error: ErrorType.RULE,
                    })
                );
            } else {
                const generalErrorData = error?.response?.data;
                if (generalErrorData) {
                    dispatch(
                        postParseXMLFileSuccess({
                            errors: [generalErrorData.error],
                            error: ErrorType.SYSTEM,
                        })
                    );
                }
            }
        }
    };
}

export const postXmlFile3wm = () => {
    return {
        type: CREATE_FILE_XML_3WM,
    };
};

export const postXMLFile3wmSuccess = (result) => {
    return {
        type: CREATE_FILE_XML_3WM_SUCCESS,
        value: result,
    };
};

export const postXmlFile3wmError = (error, result) => {
    return {
        type: CREATE_FILE_XML_3WM_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchPostFileXml3wm(
    token,
    data,
    purchaseOrderId = '',
    lineItemsFilter,
    allLineItems = false,
    wayMatch = '3wm'
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const formData = new FormData();
    formData.append('xml', data.xml);
    formData.append('pdf', data.pdf);
    formData.append('lineItemsFilter', JSON.stringify(lineItemsFilter));
    formData.append('allLineItems', allLineItems);

    return async (dispatch) => {
        dispatch(postXmlFile3wm());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/v2/purchase-order/upload-bill/validate-xml/${wayMatch}`,
                formData,
                {headers}
            );
            if (response) {
                dispatch(postXMLFile3wmSuccess({...response.data?.data, error: ErrorType.NONE}));
            }
        } catch (error) {
            if (['ERR_CONNECTION_REFUSED', 'ERR_NETWORK'].includes(error.code)) {
                dispatch(
                    postXMLFile3wmSuccess({error: ErrorType.NETWORK, errors: ['network.error']})
                );
            }

            if (error.response?.data?.error === 'auth.unauthorized') {
                dispatch(
                    postXmlFile3wmError({error: ErrorType.AUTH, errors: ['auth.unauthorized']})
                );
            }

            const ruleValidationData = error?.response?.data?.data;
            let errors = ruleValidationData ? ruleValidationData.errors : [];
            if (
                ruleValidationData &&
                ruleValidationData.errors &&
                ruleValidationData.orders &&
                errors.length > 0
            ) {
                const type = ruleValidationData.orders[0].buyerCompany.companyGroup;
                if (type === 'MARKETPLACE') {
                    errors = errors.map((error) => {
                        return switchErrors[error] || error;
                    });
                }
            }
            if (ruleValidationData) {
                dispatch(
                    postXMLFile3wmSuccess({
                        errors: errors,
                        error: ErrorType.RULE,
                    })
                );
            } else {
                const generalErrorData = error?.response?.data;
                if (generalErrorData) {
                    dispatch(
                        postXMLFile3wmSuccess({
                            errors: [generalErrorData.error],
                            error: ErrorType.SYSTEM,
                        })
                    );
                }
            }
        }
    };
}

export function fetchPostFileXml2wm(
    token,
    data,
    purchaseOrderId = '',
    lineItemsFilter,
    allLineItems = false,
    wayMatch = '2wm',
    invoiceMap = []
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const formData = new FormData();
    formData.append('xml', data.xml);
    formData.append('pdf', data.pdf);
    formData.append('lineItemsFilter', JSON.stringify(lineItemsFilter));
    formData.append('allLineItems', allLineItems);
    formData.append('invoiceMap', JSON.stringify(invoiceMap));

    return async (dispatch) => {
        dispatch(postXmlFile3wm());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/v2/purchase-order/upload-bill/validate-xml/${wayMatch}`,
                formData,
                {headers}
            );
            if (response) {
                dispatch(postXMLFile3wmSuccess({...response.data?.data, error: ErrorType.NONE}));
            }
        } catch (error) {
            if (['ERR_CONNECTION_REFUSED', 'ERR_NETWORK'].includes(error.code)) {
                dispatch(
                    postXMLFile3wmSuccess({error: ErrorType.NETWORK, errors: ['network.error']})
                );
            }

            if (error.response?.data?.error === 'auth.unauthorized') {
                dispatch(
                    postXmlFile3wmError({error: ErrorType.AUTH, errors: ['auth.unauthorized']})
                );
            }

            const ruleValidationData = error?.response?.data?.data;
            if (ruleValidationData) {
                dispatch(
                    postXMLFile3wmSuccess({
                        errors: ruleValidationData.errors,
                        error: ErrorType.RULE,
                    })
                );
            } else {
                const generalErrorData = error?.response?.data;
                if (generalErrorData) {
                    dispatch(
                        postXMLFile3wmSuccess({
                            errors: [generalErrorData.error],
                            error: ErrorType.SYSTEM,
                        })
                    );
                }
            }
        }
    };
}

export const getEntrySheet = () => {
    return {
        type: GET_ORDER_ENTRY_SHEET,
    };
};
export const getEntrySheetSuccess = (result) => {
    return {
        type: GET_ORDER_ENTRY_SHEET_SUCCESS,
        value: result,
    };
};
export const getEntrySheetError = (error, result) => {
    return {
        type: GET_ORDER_ENTRY_SHEET_ERROR,
        value: error,
        payload: result,
    };
};

export function fetchGetEntrySheet(token, sheetId) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(getEntrySheet());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/purchase-order/reference/${sheetId}`,
                {headers}
            );
            dispatch(getEntrySheetSuccess(response.data));
        } catch (error) {
            dispatch(getEntrySheetError(true));
        }
    };
}

export const postXmlFileNote = () => {
    return {
        type: CREATE_FILE_XML_NOTE,
    };
};

export const postXMLFileNoteSuccess = (result) => {
    return {
        type: CREATE_FILE_XML_NOTE_SUCCESS,
        value: result,
    };
};

export const postXmlFileNoteError = (error, result) => {
    return {
        type: CREATE_FILE_XML_NOTE_ERROR,
        value: error,
        payload: result,
    };
};
export function fetchPostFileXmlPdfNote(token, data, lineItemsFilter) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const formData = new FormData();
    formData.append('xml', data.xml);
    formData.append('pdf', data.pdf);
    formData.append('lineItems', JSON.stringify(lineItemsFilter));
    return async (dispatch) => {
        dispatch(postXmlFileNote());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/v2/purchase-order/invoice/credit-notes`,
                formData,
                {headers}
            );
            if (response) {
                dispatch(postXMLFileNoteSuccess({...response.data?.data, error: ErrorType.NONE}));
            }
        } catch (error) {
            if (['ERR_CONNECTION_REFUSED', 'ERR_NETWORK'].includes(error.code)) {
                dispatch(
                    postXMLFileNoteSuccess({
                        error: ErrorType.NETWORK,
                        errors: ['network.error'],
                    })
                );
            }

            if (error.response?.data?.error === 'auth.unauthorized') {
                dispatch(
                    postXmlFileNoteError(
                        {error: ErrorType.AUTH, errors: ['auth.unauthorized']},
                        null
                    )
                );
            }

            const ruleValidationData = error?.response?.data?.data;

            if (ruleValidationData) {
                dispatch(
                    postXmlFileNoteError(
                        {error: ErrorType.AUTH, errors: ruleValidationData.errors},
                        null
                    )
                );
            } else {
                const generalErrorData = error?.response?.data;
                if (generalErrorData) {
                    dispatch(
                        postXmlFileNoteError(
                            {
                                errors: [generalErrorData.error],
                                error: ErrorType.SYSTEM,
                            },
                            null
                        )
                    );
                }
            }
        }
    };
}

export function postZipMassive() {
    return {
        type: POST_ZIP_MASSIVE,
    };
}

export const postZipMassiveSuccess = (result) => {
    return {
        type: POST_ZIP_MASSIVE_SUCCESS,
        value: result,
    };
};

export const postZipMassiveError = (error, result) => {
    return {
        type: POST_ZIP_MASSIVE_ERROR,
        value: error,
        payload: result,
    };
};

export const setMassiveSuccess = (result) => {
    return {
        type: SET_MASSIVE_SUCCESS,
        value: result,
    };
};

function filterCollectionWithErrors(collection) {
    return collection
        .map((item) => {
            const filteredEntrySheets = item.entrySheets.filter(
                (entrySheet) => entrySheet.errors.length > 0
            );
            if (filteredEntrySheets.length > 0) {
                return {
                    poReferenceId: item.poReferenceId,
                    entrySheets: filteredEntrySheets,
                    hasPositions: item.hasPositions,
                };
            }
            return undefined;
        })
        .filter((item) => item !== undefined);
}

export const postZipMassiveErrorDomain = (error, result) => {
    return {
        type: POST_ZIP_MASSIVE_ERROR_DOMAIN,
        value: error,
        payload: result,
    };
};

export function fetchPostZipMassive(token, file) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const formData = new FormData();
    formData.append('zip', file);
    return async (dispatch) => {
        dispatch(postZipMassive());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/v2/purchase-order/upload-bills/massive-validate`,
                formData,
                {headers}
            );
            if (response) {
                dispatch(postZipMassiveSuccess({...response.data?.data, error: ErrorType.NONE}));
            }
        } catch (error) {
            if (['ERR_CONNECTION_REFUSED', 'ERR_NETWORK'].includes(error.code)) {
                dispatch(
                    postZipMassiveSuccess({
                        error: ErrorType.NETWORK,
                        errors: ['network.error'],
                    })
                );
            }

            if (error.response?.data?.error === 'auth.unauthorized') {
                dispatch(
                    postZipMassiveError(
                        {error: ErrorType.AUTH, errors: ['auth.unauthorized']},
                        null
                    )
                );
            }

            const ruleValidationData = error?.response?.data?.data;

            if (ruleValidationData) {
                const err = filterCollectionWithErrors(ruleValidationData.errors);
                dispatch(postZipMassiveError({error: ErrorType.AUTH, errors: err}, null));
            } else {
                const generalErrorData = error?.response?.data;
                if (generalErrorData) {
                    dispatch(
                        postZipMassiveErrorDomain(
                            {
                                errors: [generalErrorData.error],
                                error: ErrorType.SYSTEM,
                            },
                            null
                        )
                    );
                }
            }
        }
    };
}
export function fetchGetOrdersAgents(token, page, limit, filters = {}) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        let params = null;
        if (Object.keys(filters).length === 0) {
            params = {
                page,
                limit,
            };
        } else {
            params = {
                page,
                limit,
                initDate: filters.dateIni,
                endDate: filters.dateEnd,
                year: filters.year,
                society: filters.society ?? '',
                pediment: filters.pediment ?? '',
                folio: filters.folio ?? '',
                status: filters.status ?? '',
                aa: filters.aa ?? '',
            };
        }
        const queryString = StringUtils.jsonToQueryString(params);

        dispatch(getOrdersAgents());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/customs-agents/customs-requests${queryString}`,
                {headers}
            );
            dispatch(getOrdersAgentsSuccess(response.data, response.data));
        } catch (error) {
            dispatch(getOrdersAgentsError(true));
        }
    };
}
export const getExpenseAccount = () => {
    return {
        type: GET_EXPENSE_ACCOUNT,
    };
};
export const getExpenseAccountSuccess = (result, metadata) => {
    return {
        type: GET_EXPENSE_ACCOUNT_SUCCESS,
        value: result,
        metadata: metadata,
    };
};
export const getExpenseAccountError = (error) => {
    return {
        type: GET_EXPENSE_ACCOUNT_ERROR,
        value: error,
    };
};
export function fetchGetExpenseAccounts(
    customsAgentCode,
    customRequest,
    token,
    page = 1,
    limit = 10,
    filters = {}
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        let params = null;
        if (Object.keys(filters).length === 0) {
            params = {
                page,
                limit,
            };
        }

        const queryString = StringUtils.jsonToQueryString(params);

        dispatch(getExpenseAccount());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/customs-agents/${customsAgentCode}/custom-request/${customRequest}${queryString}`,
                {headers}
            );
            dispatch(getExpenseAccountSuccess(response.data, response.data));
        } catch (error) {
            dispatch(getExpenseAccountError(true));
        }
    };
}

export function sendAcceptTerms() {
    return {
        type: SEND_ACCEPT_TERMS,
    };
}

export const sendAcceptTermsSuccess = (result) => {
    return {
        type: SEND_ACCEPT_TERMS_SUCCESS,
        value: result,
    };
};

export const sendAcceptTermsError = (error, result) => {
    return {
        type: SEND_ACCEPT_TERMS_ERROR,
        value: error,
        payload: result,
    };
};

/**
 * Fetches and patches the acceptance of terms for given purchase orders.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.token - The authorization token.
 * @param {boolean} params.accepted - The acceptance/rejection status.
 * @param {string[]} params.purchaseOrderIds - The list of purchase order IDs.
 * @param {string} params.errorMessage - The error message to display.
 * @returns {Function} A thunk function that performs the async operation.
 */
export function patchAcceptTerms({token, accepted, purchaseOrderIds, errorMessage}) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(sendAcceptTerms());
        const body = {
            accepted,
            orders: purchaseOrderIds,
        };
        try {
            const response = await axios.patch(
                `${process.env.REACT_APP_BASE_URL}/v2/terms-conditions/purchase-order`,
                {...body},
                {headers}
            );
            if (!accepted) {
                dispatch(setIdsPurchaseOrderAcceptedAndRejectedTermsCache(purchaseOrderIds));
            }
            await dispatch(sendAcceptTermsSuccess(response.data));
        } catch (error) {
            await dispatch(sendAcceptTermsError(true));
            ErrorToast({title: errorMessage, type: 'error'});
            throw error;
        }
    };
}

export function getMassiveJob() {
    return {
        type: GET_MASSIVE_JOB,
    };
}

export const getMassiveJobSuccess = (result) => {
    return {
        type: GET_MASSIVE_JOB_SUCCESS,
        value: result,
    };
};

export const getMassiveJobError = (error, result) => {
    return {
        type: GET_MASSIVE_JOB_ERROR,
        value: error,
        payload: result,
    };
};

export const getMassiveJobErrorDomain = (error, result) => {
    return {
        type: GET_MASSIVE_JOB_ERROR_DOMAIN,
        value: error,
        payload: result,
    };
};

export function getRequestZipMassive(token, massiveJobId) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch) => {
        dispatch(getMassiveJob());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/purchase-order/massive/${massiveJobId}`,
                {headers}
            );
            if (response) {
                dispatch(getMassiveJobSuccess({...response.data?.data, error: ErrorType.NONE}));
            }
        } catch (error) {
            if (['ERR_CONNECTION_REFUSED', 'ERR_NETWORK'].includes(error.code)) {
                dispatch(
                    getMassiveJobSuccess({
                        error: ErrorType.NETWORK,
                        errors: ['network.error'],
                    })
                );
            }

            if (error.response?.data?.error === 'auth.unauthorized') {
                dispatch(
                    getMassiveJobError({error: ErrorType.AUTH, errors: ['auth.unauthorized']}, null)
                );
            }
        }
    };
}

export const setIdsPurchaseOrderAcceptedAndRejectedTermsCache = (purchaseOrderIds) => {
    return {
        type: ACCEPTED_AND_REJECTED_TERMS_CACHE,
        payload: purchaseOrderIds,
    };
};

export const cleanAcceptedAndRejectedTermsCache = () => {
    return {
        type: CLEAN_ACCEPTED_AND_REJECTED_TERMS_CACHE,
    };
};
